import React, { ReactNode, useRef } from 'react';
import classnames from 'classnames';
import { TableColumnDef } from "components/tables/Table";
import { Checkbox, Radio } from '@blueprintjs/core';
import { Icon, IconNames } from '../../icons';

export type TableRow = {
    id?: string,
    index?: number,
    values?: Array<Record<any, any>>
}

export type TableCell = {
    row: TableRow,
    column: TableColumnDef,
    value: any
}

type TBodyProps = {
    data,
    columnCount: number,
    noDataText?: string,
    getTableBodyProps: Function,
    rows: any,
    prepareRow: Function,
    colorScheme?: string,
    getRowProps?: (row: TableRow) => Record<string, string>,
    getColumnProps?: (column: TableColumnDef) => Record<string, string>,
    getCellProps?: (cell: TableCell) => Record<string, string>,
    enableSelection?: boolean,
    maxSelectionCount?: number,
    showSelectionControl?: boolean,
    selectedRowsCount?: number,
    selectOnRowClick?: boolean,
    toggleAllRowsSelected?: (set?: Boolean) => void,
    hasExpandableRows?: boolean,
    expandOnlyOneRow?: boolean,
    expandOnRowClick?: boolean,
    toggleAllRowsExpanded?: (set?: Boolean) => void,
}

// The type 'any' has been used here because react-table v7 doesn't have a well defined type structure yet
// https://spectrum.chat/react-table/general/the-types-for-typescript-are-outdated~79730c66-b5f8-48f0-b8d9-a187c3d2f435

// Renders Tbody to display empty data
const RenderEmptyTbody = (options: {
    columns: number;
    children: React.ReactNode;
}) => {
    return (
        <tbody>
        <tr>
            <td className="no-data" colSpan={options.columns}>
                {options.children}
            </td>
        </tr>
        </tbody>
    );
};

export const TBody = ({
    data,
    columnCount,
    noDataText,
    getTableBodyProps,
    rows,
    prepareRow,
    colorScheme,
    enableSelection,
    maxSelectionCount,
    selectedRowsCount,
    showSelectionControl,
    selectOnRowClick,
    hasExpandableRows,
    expandOnlyOneRow,
    expandOnRowClick,
    toggleAllRowsExpanded = () => {},
    toggleAllRowsSelected = () => {},
    getRowProps = () => ({}),
    getColumnProps = () => ({}),
    getCellProps = () => ({}),
}: TBodyProps) => {

    const classes = classnames("tir-ui-body", colorScheme);

    const lastUserSelectedRow = useRef<string|null>(null);
    const totalColumnsCount = columnCount + (enableSelection && showSelectionControl ? 1 : 0) + (hasExpandableRows ? 1 : 0);

    function addRowDepth (depth: number) {
        const output:ReactNode[] = [];
        for (let i = 0; i < depth; i++) {
            output.push(<span key={"spacer-" + i} className="row-depth-spacer"/>);
        }
        return output;
    }

    // Check if data is empty.
    // Yes: Render empty tbody
    // No: Render tbody with data
    if (!data || !data.length) {
        return (
            <RenderEmptyTbody columns={totalColumnsCount}>
                {noDataText}
            </RenderEmptyTbody>
        );
    } else {
        // getTableBodyProps, page, prepareRow - these are given by useTable in the Table component which is passed
        // as a prop to TBody
        return (
            <tbody {...getTableBodyProps()} className={classes}>
            {// Loop over the table rows
                rows.map((row, index) => {
                    // Prepare the row for display
                    prepareRow(row);
                    const rowProps = row.getRowProps(getRowProps(row));
                    let cssClasses:String[] = [];
                    // If row's data had a className property, then apply it at the row level.
                    if (row.original.className) {
                        cssClasses.push(row.original.className);
                    }
                    let interactiveRow = false;
                    if (enableSelection && row.original.selectable !== false) {
                        if (selectOnRowClick) {
                            interactiveRow = true;
                        }
                        cssClasses.push("row-selectable");
                        if (row.isSelected) {
                            cssClasses.push("row-selected");
                        }
                    }
                    if (row.canExpand || row.original.subComponent) {
                        cssClasses.push("row-expandable");
                        if (row.isExpanded) {
                            cssClasses.push("row-expanded");
                        }
                        if (expandOnRowClick) {
                            interactiveRow = true;
                        }
                    }
                    if (interactiveRow) {
                        cssClasses.push("row-interactive");
                    }
                    if (row.depth > 0) {
                        cssClasses.push("sub-row");
                        cssClasses.push("sub-row-depth-" + row.depth);
                    }
                    if (cssClasses.length > 0) {
                        rowProps.className = rowProps.className ? rowProps.className + " " + cssClasses.join(" ") : cssClasses.join(" ");
                    }
                    return (<React.Fragment key={"row-fragment-" + row.id}>
                        <tr key={"row-" + row.id}
                            data-row-id={row.original.id === undefined ? index : row.original.id}
                            onClick={e => {
                                if (selectOnRowClick) {
                                    if (maxSelectionCount === 1) {
                                        toggleAllRowsSelected(false);
                                        lastUserSelectedRow.current = row.isSelected ? null : row.index;
                                        row.toggleRowSelected(!row.isSelected);
                                    } else {
                                        if (e.ctrlKey || e.metaKey) {
                                            if (!row.isSelected) {
                                                lastUserSelectedRow.current = row.index;
                                            }
                                            row.toggleRowSelected();
                                        } else if (e.shiftKey && lastUserSelectedRow.current !== null) {
                                            const [fromIndex, toIndex] = lastUserSelectedRow.current > row.index ? [row.index, lastUserSelectedRow.current] : [lastUserSelectedRow.current, row.index];
                                            for (const row of rows) {
                                                const shouldRowBeSelected = row.index >= fromIndex && row.index <= toIndex;
                                                if (row.isSelected !== shouldRowBeSelected) {
                                                    row.toggleRowSelected(shouldRowBeSelected);
                                                }
                                            }
                                            e.preventDefault();
                                            document?.getSelection()?.removeAllRanges();
                                        } else {
                                            toggleAllRowsSelected(false);
                                            lastUserSelectedRow.current = row.isSelected ? null : row.index;
                                            row.toggleRowSelected(!row.isSelected);
                                        }
                                    }
                                }
                                if (expandOnRowClick && (row.canExpand || row.original.subComponent)) {
                                    if (expandOnlyOneRow && !row.isExpanded && row.depth === 0) {
                                        toggleAllRowsExpanded(false);
                                    }
                                    row.toggleRowExpanded();
                                }
                            }}
                            {...rowProps}
                        >
                            {
                                hasExpandableRows && <td className="expand-row">
                                    { addRowDepth(row.depth) }
                                    {
                                        (row.canExpand || row.original.subComponent) &&
                                        <Icon
                                            data-testid="expand-row-control"
                                            icon={row.isExpanded ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_RIGHT}
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (expandOnlyOneRow && !row.isExpanded && row.depth === 0) {
                                                    toggleAllRowsExpanded(false);
                                                }
                                                row.toggleRowExpanded();
                                            }}
                                        />
                                    }
                                </td>
                            }
                            {
                                enableSelection && showSelectionControl &&
                                (
                                    // If this data row explicitly has 'selectable' as false, then don't render selection control
                                    row.original.selectable === false ?
                                    <td className="select-row no-selection"></td> :
                                    <td className="select-row" onClick={e => {
                                        e.stopPropagation();
                                    }}>{
                                        maxSelectionCount === 1 ?
                                        <Radio
                                            checked={row.isSelected}
                                            onClick={e => {
                                                e.stopPropagation();
                                                toggleAllRowsSelected(false);
                                                row.toggleRowSelected(true);
                                            }}
                                            data-testid="toggle-row-selection"
                                        />
                                        :
                                        <Checkbox
                                            checked={row.isSelected}
                                            onClick={e => {
                                                e.stopPropagation();
                                                if (e.shiftKey && lastUserSelectedRow.current !== null) {
                                                    const [fromIndex, toIndex] = lastUserSelectedRow.current > row.index ? [row.index, lastUserSelectedRow.current] : [lastUserSelectedRow.current, row.index];
                                                    for (const row of rows) {
                                                        const shouldRowBeSelected = row.index >= fromIndex && row.index <= toIndex;
                                                        if (row.isSelected !== shouldRowBeSelected) {
                                                            row.toggleRowSelected(shouldRowBeSelected);
                                                        }
                                                    }
                                                } else {
                                                    lastUserSelectedRow.current = row.isSelected ? null : row.index;
                                                    row.toggleRowSelected();
                                                }
                                            }}
                                            disabled={!row.isSelected && Boolean(selectedRowsCount && maxSelectionCount && selectedRowsCount >= maxSelectionCount)}
                                            data-testid="toggle-row-selection"
                                        />
                                    }</td>
                                )
                            }
                            {// Loop over the rows cells
                                row.cells.map(cell => {
                                    // Apply the cell props
                                    const className = classnames({
                                        [cell.column.className]:
                                        cell.column.className,
                                    });
                                    return (
                                        <td
                                            {...cell.getCellProps([
                                                {
                                                    className: className,
                                                    style: cell.column.style,
                                                },
                                                getColumnProps(cell.column),
                                                getCellProps(cell)
                                            ])}
                                        >
                                            {// Render the cell contents
                                                cell.render('Cell')}
                                        </td>
                                    );
                                })}
                        </tr>
                        {
                            row.isExpanded && row.original.subComponent ?
                            <tr key={"row-" + row.id + "-subcomponent"}>
                                <td className="row-sub-component-container" colSpan={totalColumnsCount}>
                                    {row.original.subComponent}
                                </td>
                            </tr>
                            : undefined
                        }
                    </React.Fragment>);
                })}
            </tbody>
        );
    }
};
