import React, { FunctionComponent, useEffect, useState } from 'react';
import { AuthService } from '../services/AuthService';
import { AuthError, ERROR_CODES, ErrorProps } from '../error/AuthError';
import { CloudAuthProps } from '../components/TIRAuthentication';
import { STRINGS } from '../library_strings';

const renderError = (
    error: ErrorProps,
    errorComponent?: FunctionComponent<{ error: ErrorProps }>
) => {
    return <AuthError error={error} errorComponent={errorComponent}/>;
};


export const SecuredContent = (props: CloudAuthProps) => {
    const [error, setError] = useState({errorCode: '', errorMessage: ''});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        AuthService.authProvider.init(AuthService.getTenant().idp).then(()=>{
            //do nothing as init call will do one of these
            // 1. Redirect to login
            // 2. Fetch authentication state
            // 3. Return error
            setLoading(false);
        }, (error) => {
            setLoading(false);
            setError(error);
        });
    }, [AuthService])

    if (!loading && AuthService.isAuthenticated()) {
        AuthService.authProvider.cleanRedirectUrl();
        return props.children;
    } else if (!loading && error && (error.errorCode || error.errorMessage)) {
        const errorProps: ErrorProps = error;
        if (error.errorCode === ERROR_CODES.tenantDoesNotExist) {
            errorProps.title = STRINGS.ERROR.tenantDoesNotExistTitle;
        }
        return renderError(errorProps, props.errorComponent);
    } else {
        if (props.loadingComponent) {
            return props.loadingComponent({
                visible: true,
                loadingText: STRINGS.authenticating,
            });
        }
        return <div>{STRINGS.authenticating}</div>;
    }
};
