import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, InputGroup, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { SuggestInput } from '../suggest-input';
import { LinkButton } from '../..';
import { DatePicker, DateRangePicker } from '@blueprintjs/datetime';

export interface ColumnFilterControlProps {
    /** Passthrough of `filterPlaceHolder` property if configured for this column in the table's column definition */
    placeholder: string;
    /** This is the column information that will be passed by React Table */
    column: {
        /** current filter value set for this column */
        filterValue: string | string[];
        /** callback to be used to update the filter value set for this column */
        setFilter: (newFilterValue: string | string[]) => void;
        [x: string]: any;
    },
    /** Can this control be toggled ON and OFF? This will be off when `showFilterRow` in table's config is set to true to show it always */
    canToggle: boolean;
    /** Header content will be passed as children. The filter control can choose to
     * show or hide the header content as needed by deciding if it should return this back or not */
    children: React.ReactNode;
    /** Options that can be passed to the filter control as part of filterProps in table */
    options?: string[];
    /** A label to be used for the control to clear the filter if such a control is supported by the filter control being used */
    clearFilterControlLabel?: string;
    /** A way of passing config that is meant to override some of the default props used by the underlying filter field  */
    filterFieldConfig?: any;
    [x: string]: any;
}

interface  WrapColumnFilterWithToggleProps extends ColumnFilterControlProps {
    /** The control to be displayed when filter button is activated */
    filterControl: React.ReactNode;
    /** Header contents to be displayed */
    children: React.ReactNode;
    /** If this flag is passed as true, the header content will be displayed
     * even when the filter is activated and the filter control is displayed.
     * By default, the filter control will replace the header content when activated */
    showHeaderContentAlways?: boolean;
    /** Method that if provided would be called to set focus on the field being wrapped when activated */
    focusOnField?: () => void;
}

/** In many cases, we'd want the column-level filter control to initially not be displayed.
 * We'd rather have a filter button displayed which when activated should show the actual
 * control for entering the filter value. Instead of having to build this behaviour for each
 * filter control, I have built a wrapper component which can wrap a filter control and perform
 * the toggling logic. It also handles looking at flags like canToggle, the presence of value,
 * focussing on the field, etc so that we don't have to duplicate code.
 */
export function WrapColumnFilterWithToggle ({
    canToggle = true,
    column: {
        multiValueFilter,
        filterValue = multiValueFilter ? [] : "",
    },
    filterControl,
    children,
    showHeaderContentAlways = false,
    focusOnField,
}: WrapColumnFilterWithToggleProps) {
    const [showSearch, setShowSearch] = useState(!canToggle || (Array.isArray(filterValue) ? filterValue.join("") : filterValue) !== "");

    const showSearchRef = useRef(showSearch);
    showSearchRef.current = showSearch;
    const filterValueRef = useRef(filterValue);
    filterValueRef.current = filterValue;

    const wrapperRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (canToggle && showSearch && focusOnField) {
            // Focus the control when it is displayed
            focusOnField();
        }
    }, [showSearch]);

    useEffect(() => {
        if (canToggle) {
            document.addEventListener("mousedown", onClickedOutsideControlCheck);
        }
        return () => {
            document.removeEventListener("mousedown", onClickedOutsideControlCheck);
        }
    }, []);
    const onClickedOutsideControlCheck = useCallback(e => {
        if (!wrapperRef.current?.contains(e.target) && showSearchRef.current && (Array.isArray(filterValueRef.current) ? filterValueRef.current.join("") : filterValueRef.current) === "") {
            setShowSearch(false);
        }
    }, [showSearch]);

    const headerContent = <span className="header-with-filter-content">{ children }</span>;

    // Wrapping filter control so that clicks don't translate to header-level click actions (e.g. sort)
    const wrappedFilterControl = <span style={!canToggle || showHeaderContentAlways ? { display: "inline-block" } : {}} onClick={e => e.stopPropagation()}>{filterControl}</span>
    if (canToggle) {
        return <span className="wrap-with-filter-toggle-container" ref={wrapperRef}>
            {
                showSearch ?
                <>{ showHeaderContentAlways && headerContent } { wrappedFilterControl }</> :
                <>{ children }<Button className="filter-icon show-on-hover no-filter" icon={IconNames.FILTER} minimal small onClick={e => { e.stopPropagation(); setShowSearch(true); }}/></>
            }
        </span>;
    } else {
        return <>{ headerContent } { wrappedFilterControl }</>;
    }
};


/** A simple text control that allows the user to type in a filter string.
 * This text control is wrapped within a toggle and so will show up only when
 * user activates it.
 */
export function DefaultColumnFilterControl (props:ColumnFilterControlProps) {
    const {
        column: { filterValue = "", setFilter, multiValueFilter },
        placeholder = "",
        filterFieldConfig,
    } = props;
    const inputControlRef = useRef<HTMLInputElement | null>(null);
    return <WrapColumnFilterWithToggle
        {...props}
        focusOnField={() => inputControlRef.current?.focus()}
        filterControl={
            <InputGroup
                inputRef={ref => {
                    inputControlRef.current = ref;
                }}
                placeholder={placeholder}
                leftIcon={IconNames.SEARCH}
                value={Array.isArray(filterValue) ? filterValue.join(",") : filterValue}
                onChange={e => {
                    const rawValue = e.target.value as string;
                    const filterValue = multiValueFilter ? rawValue.split(",") : rawValue;
                    setFilter(filterValue);
                }}
                rightElement={<Button minimal icon={IconNames.CROSS} onClick={() => setFilter(multiValueFilter ? [""] : "")}/>}
                {...filterFieldConfig}
            />
        }
    />;
};

/** A filter control similar to the default text filter control but also supports autocompletion
 * based on available rows. This control however does not support multiple values.
 */
export function AutocompleteColumnFilterControl (props:ColumnFilterControlProps) {
    const {
        column: {
            filterValue = "",
            setFilter,
            accessor,
        },
        flatRows,
        placeholder = "",
        options,
        filterFieldConfig,
    } = props;
    const inputControlRef = useRef<HTMLInputElement | null>(null);
    const items = options ?
        options.map(option => ({ value: option, display: option })) :
        Object.keys(
            flatRows
            .map(row => accessor(row.values))
            .reduce((output, value) => {
                output[value] = true;
                return output;
            }, {})
        )
        .sort()
        .map(value => ({ value, display: value }));
    const controlClearedIndex = useRef(0);
    return <WrapColumnFilterWithToggle
        {...props}
        focusOnField={() => inputControlRef.current?.focus()}
        filterControl={
            <SuggestInput
                key={"ac-filter-" + controlClearedIndex.current}
                inputProps={{
                    placeholder,
                    leftIcon: IconNames.SEARCH,
                    rightElement: <Button minimal icon={IconNames.CROSS} onClick={() => {
                        // controlClearedIndex variable is a Hack to cause blueprint's suggest
                        // control to re-render. Without this, I encountered a bug where the
                        // inner control's value wasn't getting updated
                        controlClearedIndex.current++;
                        setFilter("");
                    }}/>,
                    inputRef: ref => {
                        inputControlRef.current = ref;
                    },
                    onChange: e => setFilter(e.target.value),
                }}
                popoverProps={{
                    usePortal: false,
                    minimal: true,
                }}
                selectedItem={{ display: filterValue, value: filterValue }}
                onQueryChange={query => setFilter(query)}
                onItemSelect={item => setFilter(item.value)}
                resetOnQuery={false}
                resetOnClose={false}
                resetOnSelect={false}
                createNewItemFromQuery={query => ({ display: query, value: query })}
                createNewItemPosition="first"
                items={items}
                {...filterFieldConfig}
            />
        }
    />;
};

/** A filter control that shows a list of options with checkboxes. If you wish to provide
 * a hardcoded options list, you can use the `options` property in the column's definition
 * under `filterProps` object.
 */
export function MultiSelectFilterControl (props:ColumnFilterControlProps) {
    const {
        children,
        column: {
            filterValue = [],
            setFilter,
            accessor,
        },
        flatRows,
        options,
        clearFilterControlLabel = "Clear",
    } = props;
    const itemsRef = useRef<Array<string>>([]);
    useEffect(() => {
        if (itemsRef.current.length === 0) {
            if (options) {
                itemsRef.current = options;
            } else if (flatRows.length > 0 && itemsRef.current.length === 0) {
                itemsRef.current = flatRows.map(row => accessor(row.values));
            }
        }
    }, [flatRows, options]);
    const uniqueItems = Object.keys(itemsRef.current.reduce((output, item) => {
        output[item] = true;
        return output;
    }, {})).sort();
    const currentActiveItems = Array.isArray(filterValue) ? filterValue.reduce((output, value) => {
        output[value] = true;
        return output;
    }, {}) : {};
    const noSelections = Object.keys(currentActiveItems).length === 0;
    return <>{children} <span onClick={e => { e.stopPropagation(); }}><Popover
        content={<div>
            <div style={{ maxHeight: "300px", overflow: "auto", padding: "10px", paddingBottom: "0" }}>
                {uniqueItems.map(item => <Checkbox
                    key={"multi-item-filter-" + item}
                    checked={currentActiveItems[item] || false}
                    onChange={e => {
                        if (e.currentTarget.checked) {
                            currentActiveItems[item] = true;
                        } else {
                            delete currentActiveItems[item];
                        }
                        setFilter(Object.keys(currentActiveItems));
                    }}
                    label={item}
                />)}
            </div>
            {!noSelections && <LinkButton onClick={() => setFilter([])} style={{ padding: "10px", paddingTop: "0" }}>{clearFilterControlLabel}</LinkButton>}
        </div>}
        interactionKind="click"
    ><Button
        className={"filter-icon" + (noSelections ? " show-on-hover no-filter" : "")}
        icon={noSelections ? IconNames.FILTER : IconNames.FILTER_KEEP}
        minimal
        small
    /></Popover>
    </span></>;
};

/** A filter control that can filter by either a single date or a date range.
 * It will automatically switch between single and range based on if the column
 * definition had multiValueFilter as true or false. There is a `dateRangeFilterFunction`
 * method which can be used in conjunction with this filter control to perform the
 * actual filtering logic.
 */
export function DateRangeFilterControl (props:ColumnFilterControlProps) {
    const {
        children,
        column: {
            multiValueFilter,
            filterValue,
            setFilter,
        },
        filterFieldConfig,
        clearFilterControlLabel = "Clear",
    } = props;

    const allowRange = multiValueFilter;
    const [startDateFromValue, endDateFromValue] = Array.isArray(filterValue) ? filterValue : [filterValue];
    const startDate = startDateFromValue ? new Date(Number(startDateFromValue)) : null;
    const endDate = (allowRange && endDateFromValue) ? new Date(Number(endDateFromValue)) : null;
    return <>{children} <span onClick={e => { e.stopPropagation(); }}><Popover
        content={<div style={{ padding: "10px" }}>
            {
                allowRange ?
                <DateRangePicker
                    onChange={(dateRange) => {
                        const [start, end] = dateRange;
                        if (start && end) {
                            setFilter([ String(start.getTime()), String(end.getTime()) ]);
                        }
                    }}
                    allowSingleDayRange
                    singleMonthOnly
                    shortcuts={false}
                    key={"date-range-" + startDate?.getTime() + "-" + endDate?.getTime()}
                    defaultValue={startDate && endDate ? [startDate, endDate] : undefined}
                    maxDate={new Date()}
                    {...filterFieldConfig}
                /> :
                <DatePicker
                    onChange={date => setFilter(String(date.getTime()))}
                    key={"date-picker-" + startDate?.getTime()}
                    defaultValue={startDate || undefined}
                    highlightCurrentDay
                    maxDate={new Date()}
                    {...filterFieldConfig}
                />
            }
            <LinkButton onClick={() => setFilter("")}>{clearFilterControlLabel}</LinkButton>
        </div>}
        interactionKind="click"
        autoFocus={false}
    ><Button
        className={"filter-icon" + (filterValue ? "" : " show-on-hover no-filter")}
        icon={filterValue ? IconNames.FILTER_KEEP : IconNames.FILTER}
        minimal
        small
    /></Popover>
    </span></>;
};
/** This function is meant to be used in conjunction with DateRangeFilterControl by passing it under
 * the `filterFunction` parameter for a table's column config */
export function dateRangeFilterFunction (rows, [columnID], filterValue) {
    const [startDate, endDate] = Array.isArray(filterValue) ? filterValue : [filterValue];
    if (startDate) {
        let startDateToFilterWith, endDateToFilterWith;
        // If only a single date is present, then filter to all rows in that date
        if (!endDate) {
            startDateToFilterWith = new Date(Number(startDate)).setHours(0,0,0,0);
            endDateToFilterWith = new Date(startDateToFilterWith).setHours(23,59,59,999);
        } else {
            startDateToFilterWith = new Date(Number(startDate)).setHours(0,0,0,0);
            endDateToFilterWith = new Date(Number(endDate)).setHours(23,59,59,999);
        }
        return rows.filter(row => {
            const value = Number(row.values[columnID]);
            return Boolean(value >= startDateToFilterWith && value <= endDateToFilterWith);
        });
    } else {
        return rows;
    }
}

/** A filter control that will show a checkbox and when checked will show the rows
 * which have the column's value as true.
 */
export function CheckboxColumnFilterControl (props:ColumnFilterControlProps) {
    const {
        column: { filterValue = "", setFilter },
        children,
    } = props;
    return <>
        { children } <span className="checkbox-column-filter-holder" onClick={e => { e.stopPropagation(); }}><Checkbox
            checked={filterValue === "true"}
            onChange={e => setFilter(e.currentTarget.checked ? "true" : "")}
            inline
            style={{ margin: "0" }}
        /></span>
    </>;
};