import { filterConfigToken, filterToken } from './StructuredFilterTypes';
import { momentDateRange } from './../../utils/DateUtils';
import { FILTER_TYPE_DEFAULT, operators } from './StructuredFilterOperators';

const getConfig = (
    filterConfig: filterConfigToken[],
    key: string
): filterConfigToken | undefined => {
    return filterConfig.find(x => x.key === key);
};

export const categoryDisplay = (
    filterConfig: filterConfigToken[],
    key: string
) => {
    const categoryConfig = getConfig(filterConfig, key);
    if (
        typeof categoryConfig === 'undefined' ||
        typeof categoryConfig.display === 'undefined'
    ) {
        return key;
    }

    return categoryConfig.display;
};

export const operatorDisplay = (
    filterConfig: filterConfigToken[],
    filterToken: filterToken
) => {
    const enumValues = operatorEnumValues(filterConfig, filterToken.key);
    if (
        typeof enumValues === 'undefined' ||
        filterToken.operator === undefined
    ) {
        return undefined;
    }

    const enumOperator = enumValues.find(x => x.key === filterToken.operator);

    if (
        typeof enumOperator === 'undefined' ||
        typeof enumOperator.display === 'undefined'
    ) {
        return undefined;
    }

    return enumOperator.display;
};

export const valueDisplay = (
    filterConfig: filterConfigToken[],
    filterToken: filterToken
) => {
    const enumValues = categoryEnumValues(filterConfig, filterToken.key);
    if (typeof enumValues === 'undefined') {
        return filterToken.value;
    }

    const enumValueToken = enumValues.find(x => x.value === filterToken.value);
    if (
        typeof enumValueToken === 'undefined' ||
        typeof enumValueToken.display === 'undefined'
    ) {
        return filterToken.value;
    }

    return enumValueToken.display;
};

export const categoryEnumValues = (
    filterConfig: filterConfigToken[],
    key: string
) => {
    const categoryConfig = getConfig(filterConfig, key);
    if (
        typeof categoryConfig === 'undefined' ||
        typeof categoryConfig.values === 'undefined'
    ) {
        return undefined;
    }

    return categoryConfig.values;
};

export const operatorEnumValues = (
    filterConfig: filterConfigToken[],
    key: string
) => {
    const operatorsConfig = getConfig(filterConfig, key);
    if (
        typeof operatorsConfig === 'undefined' ||
        typeof operatorsConfig.filterType === 'undefined'
    ) {
        return operators[FILTER_TYPE_DEFAULT];
    }

    return operators[operatorsConfig.filterType];
};

export const suggestCategoryItems = (
    filterConfig: filterConfigToken[],
    filterValues: filterToken[]
) => {
    //todo revert this since unique is not supported anyways right now! have multi = true
    return filterConfig.filter(x => {
        if (!x.unique) {
            //if it's not an unique filter, it's always available
            return true;
        }
        //otherwhise check if it was already added
        return filterValues.findIndex(y => y.key === x.key) === NONE;
    });
};

const NONE = -1;

const findNextIndex = (
    filterValues: filterToken[],
    startIndex: number,
    direction: number
) => {
    let index = startIndex + direction;
    while (index > 0 && index < filterValues.length && !filterValues[index]) {
        index += direction;
    }
    return Math.min(Math.max(index, 0), filterValues.length);
};

export const isValidIndex = (filterValues: filterToken[], index: number) => {
    return index !== NONE && index < filterValues.length;
};

export const getNextActiveIndex = (
    filterValues: filterToken[],
    activeIndex: number,
    direction: number
) => {
    if (activeIndex === NONE) {
        // nothing active & moving left: select last defined value. otherwise select nothing.
        return direction < 0
            ? findNextIndex(filterValues, filterValues.length, -1)
            : NONE;
    } else {
        // otherwise, move in direction and clamp to bounds.
        // note that upper bound allows going one beyond last item
        // so focus can move off the right end, into the text input.
        return findNextIndex(filterValues, activeIndex, direction);
    }
};

export const mergeDateIntoFilters = (
    filterValues: filterToken[],
    dateRange: momentDateRange | undefined
) => {
    if (typeof dateRange === 'undefined') {
        return filterValues;
    }

    // we translate the date range to two separate params
    // this is mostly for convenience for us, in the future we can make this configurable,
    // and even handle the two values separately like normal filters but of a different "Date" type
    return filterValues.concat([
        { key: 'start_time', value: dateRange[0].unix() },
        { key: 'end_time', value: dateRange[1].unix() },
    ]);
};
