import { DateRange, IDateRangePickerProps } from '@blueprintjs/datetime';
import moment from 'moment';

export type momentDateRange = [moment.Moment, moment.Moment];

//last week
export const DEFAULT_DATE_RANGE: () => momentDateRange = () => {
  return [
    moment
      .utc()
      .subtract(6, 'days')
      .startOf('day'),
    moment.utc(),
  ];
};

export const defaultDateRangePickerProps: IDateRangePickerProps = {
  allowSingleDayRange: true,
  contiguousCalendarMonths: false,
  shortcuts: true,
  minDate: moment('2020-01-01')
    .startOf('day')
    .toDate(),
};

export const LocalToUtc = (d: moment.Moment) => {
  return moment.utc(d.format('YYYY-MM-DDTHH:mm:ss'));
};

export const UtcToLocal = (d: moment.Moment) => {
  return moment(d.format('YYYY-MM-DDTHH:mm:ss'));
};

export const momentDateRangeToBlueprintDateRange = (
  dr: momentDateRange | undefined,
  utc = true
) => {
  let ret: DateRange;
  if (dr !== undefined) {
    if (utc === false) {
      // @ts-ignore   there is null check, but it does not seem to pick it
      ret = [dr[0].toDate(), dr[1].toDate()];
    } else {
      ret = [UtcToLocal(dr[0]).toDate(), UtcToLocal(dr[1]).toDate()];
    }
    return ret;
  }
};

export const blueprintDateRangeToMomentDateRange = (
  dr: DateRange | undefined,
  utc = true
): momentDateRange | undefined => {
  // blueprint components might return only start or end date for some selections,
  // our choice is to consider from start of day to end of day of that date
  // this will need to be revisited if we start allowing a choice of time too
  if (typeof dr !== 'undefined') {
    if (dr[0] instanceof Date || dr[1] instanceof Date) {
      //if at least one of the two is a date

      // typescript here seems to not understand that since we checked that at least one was a date, that means that the other one must be one
      // @ts-ignore
      const start = (dr[0] instanceof Date
        ? moment(dr[0])
        : moment(dr[1])
      ).startOf('day');
      const end = (dr[1] instanceof Date ? moment(dr[1]) : moment(start)).endOf(
        'day'
      );

      if (utc === false) {
        //unless we really don't want utc mode
        return [start, end];
      } else {
        //do sketchy conversion to ignore timezone concept from the date
        return [LocalToUtc(start), LocalToUtc(end)];
      }
    }
  }
};
