import { filterToken } from './StructuredFilterTypes';
import { STRINGS } from './../../strings';

export const DEFAULT_EQUALS = 'default_equals';

export const FILTER_TYPE_STRING = 'string';
export const FILTER_TYPE_NUMERIC = 'numeric';
export const FILTER_TYPE_DEFAULT = 'default';

export const operators = {
    string: [
        {
            key: 'string_contains',
            display: STRINGS.STRUCTURED_FILTER.operators.contains,
        },
        {
            key: 'string_equals',
            display: STRINGS.STRUCTURED_FILTER.operators.equals,
        },
    ],
    numeric: [
        {
            key: 'numeric_equals',
            display: '=',
        },
        {
            key: 'numeric_less_than',
            display: '<',
        },
        {
            key: 'numeric_less_than_equals',
            display: '<=',
        },
        {
            key: 'numeric_greater_than',
            display: '>',
        },
        {
            key: 'numeric_greater_than_equals',
            display: '>=',
        },
    ],
    default: [
        {
            key: 'default_equals',
            display: '=',
        },
    ],
};

const getCompareValue = (item, key) => {
    const keys = key.split(".")
    if (keys.length === 1) {
        return item[key]
    } else {
        return keys.reduce((itemObj, key) => {
            return itemObj[key]
        }, item)
    }
}

export const filterData = (item: object, filters: filterToken) => {
    let itemMatch = true;
    Object.keys(filters).forEach(key => {
        if (!itemMatch) {
            return itemMatch;
        }
        const compareValue = getCompareValue(item, key)
        switch (filters[key].operator) {
            case 'string_contains':
                itemMatch = String(compareValue)
                    .toLowerCase()
                    .includes(String(filters[key].value).toLowerCase());
                break;
            case 'string_equals':
                itemMatch =
                    String(compareValue).localeCompare(
                        String(filters[key].value),
                        undefined,
                        { sensitivity: 'accent' }
                    ) === 0;
                break;
            case 'numeric_equals':
                itemMatch = Number(compareValue) === Number(filters[key].value);
                break;
            case 'numeric_less_than':
                itemMatch = Number(compareValue) < Number(filters[key].value);
                break;
            case 'numeric_less_than_equals':
                itemMatch = Number(compareValue) <= Number(filters[key].value);
                break;
            case 'numeric_greater_than':
                itemMatch = Number(compareValue) > Number(filters[key].value);
                break;
            case 'numeric_greater_than_equals':
                itemMatch = Number(compareValue) >= Number(filters[key].value);
                break;
            case 'default_equals':
                itemMatch = compareValue === filters[key].value;
                break;
            default:
                itemMatch = compareValue === filters[key].value;
        }
    });

    return itemMatch;
};
