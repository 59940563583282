import React from 'react';
import { STRINGS } from '../library_strings';

export type ErrorProps = {
  message?: string,
  errorCode?: string;
  errorMessage: string;
  title?: string;
  suggestion?: string;
};

type authErrorProps = {
  error: ErrorProps;
  errorComponent?: React.FunctionComponent<{ error: ErrorProps }>;
};

const ERROR_IDS = {
  invalidAccess: 'AADSTS50105',
};

export const ERROR_CODES = {
  invalidState: 'invalid_state_error',
  invalidAccess: 'interaction_required',
  tenantDoesNotExist: '1001',
};

const getErrorWithDefaults = (error: ErrorProps) => {
  const { errorMessage, errorCode } = error;
  let suggestion;
  if (!error.suggestion) {
    if (errorCode === ERROR_CODES.invalidState) {
      //In firefox private window, after you login you will see an invalid state error and then you need to refresh to
      // get the app working. This is a known issue being tracked at
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1468. Unless it gets resolved, we
      // handle it manually and show a suggestion for refreshing the rab to the user
      suggestion = STRINGS.ERROR.SUGGESTIONS.invalidState;
    } else if (
      errorCode === ERROR_CODES.invalidAccess &&
      errorMessage.includes(ERROR_IDS.invalidAccess)
    ) {
      //If the browser has an active microsoft session the that account will be used to login. If that account
      // does not have access to the app then it would return interaction required error with message like
      // 'AADSTS50105: The signed in user '{EmailHidden}' is not assigned to a role for the .....'
      suggestion = STRINGS.ERROR.SUGGESTIONS.invalidAccess;
    }
  }
  error.suggestion = suggestion;
  error.title = error.title || STRINGS.ERROR.defaultTitle;
  return error;
};

const renderContent = (label?: string, content?: string) => {
  if (content) {
    return (
      <p>
        <b>{label}: </b>
        {content}
      </p>
    );
  }
  return null;
};

export const AuthError = (props: authErrorProps) => {
  const error = getErrorWithDefaults(props.error);

  if (props.errorComponent) {
    return props.errorComponent({
      error: error,
    });
  } else {
    return (
      <div>
        {renderContent(
          STRINGS.ERROR.LABELS.title,
          error.title || STRINGS.ERROR.defaultTitle
        )}
        {renderContent(STRINGS.ERROR.LABELS.code, error.errorCode)}
        {renderContent(
          STRINGS.ERROR.LABELS.message,
          error.errorMessage || STRINGS.ERROR.defaultMessage
        )}
        {renderContent(STRINGS.ERROR.LABELS.suggestion, error.suggestion)}
      </div>
    );
  }
};
