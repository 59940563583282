//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { AuthService } from '../services/AuthService';
import { AuthConstants, ENV_CONFIG } from '../constants/Constants';
import { ErrorProps, AuthError } from '../error/AuthError';
import { STRINGS } from "../library_strings";

type CloudAuthProps = {
  children: React.ReactElement;
  env: 'dev' | 'staging' | 'prod'; //use one of the 3 predefined configs based on environment
  authConfig?: {
    permissions: (userId: string) => string
  };
  product?: string,
  loadingComponent?: React.FunctionComponent<{ visible?: boolean }>;
  errorComponent?: React.FunctionComponent<{ error: ErrorProps }>;
};

export const TIRAuthorization = (props: CloudAuthProps) => {
  const [authorizationState, setAuthorizationState] = useState(
    AuthConstants.IN_PROGRESS
  );
  const [error, setError] = useState();

  const authConfig = props.authConfig
    ? props.authConfig
    : props.env
    ? ENV_CONFIG[props.env]
    : ENV_CONFIG['dev'];

  useEffect(() => {
    AuthService.authorize(authConfig, props.product).then(
      () => {
        setAuthorizationState(AuthConstants.AUTHORIZED);
      },
      (err) => {
        setAuthorizationState(AuthConstants.UNAUTHORIZED);
        setError(err);
      }
    );
  }, []);

  if (!error) {
    if (authorizationState === AuthConstants.AUTHORIZED) {
      return props.children;
    } else if (authorizationState === AuthConstants.IN_PROGRESS) {
      if (props.loadingComponent) {
        return props.loadingComponent({ visible: true , loadingText: STRINGS.authorizing});
      }
      return <div>Loading</div>;
    } else {
      return null;
    }
  } else {
    // @ts-ignore-start
    return (
      <AuthError
        error={{
          title: error.title,
          errorMessage: error.message,
          errorCode: error.code,
          suggestion: error.suggestion,
        }}
        errorComponent={props.errorComponent}
      />
    );
  }
};
