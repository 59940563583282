import React from 'react';
import { Button, ButtonGroup, Classes, HTMLSelect } from '@blueprintjs/core';
import './Pagination.scss';
import { IconNames } from '../../icons';

/** an interface that describes the properties that can be passed in to the pagination controls component.*/
export type PaginationProps = {
    gotoPage: Function;
    previousPage: Function;
    nextPage: Function;
    canPreviousPage: boolean;
    canNextPage: boolean;
    pageIndex: number;
    pageCount: number;
    pageOptions: Array<any>;
    pageSize: number;
    setPageSize: Function;
    pageSizeOptions: Array<number>; //only one added by us currently
    /** notification that is sent out when the user makes a change in the page size control. */
    onPageSizeChange?: (pageSize: number) => void;
    /** notification that is sent out when the user makes a change in any of the controls that change the page index. */
    onPageIndexChange?: (pageIndex: number) => void;
};

/** Renders the pagination controls react component.
 *  @param props the properties passed in.
 *  @returns the JSX with the pagination controls. */
export const Pagination = (props: PaginationProps): JSX.Element => {
    const {
        gotoPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageIndex,
        pageOptions,
        pageCount,
        pageSize,
        setPageSize,
        pageSizeOptions,
    } = props;

    const [smallestPageSize] = pageSizeOptions;
    // If pagination was enabled but number of rows are lesser than the smallest page size, then don't render pagination control
    if (pageOptions.length === 1 && smallestPageSize && pageSize === smallestPageSize) {
        return <></>;
    }
    return (
        <div className="tir-ui-pagination">
            <ButtonGroup className="page-nav">
                <Button
                    onClick={() => {
                        notifyPageIndexChange(props.onPageIndexChange, 0);
                        gotoPage(0);
                    }}
                    disabled={!canPreviousPage}
                    icon={IconNames.CHEVRON_BACKWARD}
                ></Button>
                <Button
                    onClick={() => {
                        notifyPageIndexChange(props.onPageIndexChange, pageIndex - 1);
                        previousPage();
                    }}
                    disabled={!canPreviousPage}
                    icon={IconNames.CHEVRON_LEFT}
                ></Button>
                <input
                    className={Classes.INPUT}
                    value={pageIndex + 1}
                    onChange={e => {
                        const page = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                        notifyPageIndexChange(props.onPageIndexChange, page);
                        gotoPage(page);
                    }}
                    onFocus={event => event.target.select()}
                />
                <Button
                    onClick={() => {
                        notifyPageIndexChange(props.onPageIndexChange, pageIndex + 1);
                        nextPage();
                    }}
                    disabled={!canNextPage}
                    icon={IconNames.CHEVRON_RIGHT}
                ></Button>
                <Button
                    onClick={() => {
                        notifyPageIndexChange(props.onPageIndexChange, pageCount - 1);
                        gotoPage(pageCount - 1);
                    }}
                    disabled={!canNextPage}
                    icon={IconNames.CHEVRON_FORWARD}
                ></Button>
                <span className="page-info">
                    Showing page{' '}
                    <strong>
                        {' '}
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
            </ButtonGroup>
            <div className="page-size">
                <HTMLSelect
                    value={pageSize}
                    onChange={e => {
                        notifyPageSizeChange(props.onPageSizeChange, Number(e.target.value));
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {pageSizeOptions.map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </HTMLSelect>
            </div>
        </div>
    );
};

/** sends out a notification of a page index change. 
 *  @params onPageIndexChange the handler for page index changes.
 *  @param pageIndex the new page index. */
function notifyPageIndexChange(onPageIndexChange: ((pageIndex: number) => void) | undefined, pageIndex): void {
    if (onPageIndexChange) {
        onPageIndexChange(pageIndex);
    }
}

/** sends out a notification of a page size change. 
 *  @params onPageSizeChange the handler for page size changes.
 *  @param pageSize the new page size. */
 function notifyPageSizeChange(onPageSizeChange: ((pageIndex: number) => void) | undefined, pageSize): void {
    if (onPageSizeChange) {
        onPageSizeChange(pageSize);
    }
}
