export const en = {
    authenticating: 'Authenticating',
    authorizing: 'Authorizing',
    ERROR: {
        defaultMessage: 'There was an error encountered during authentication',
        defaultTitle: 'Authentication error',
        authorizationError: 'Authorization error',
        no_product_access: 'You do not have access to this product.',
        LABELS: {
            code: 'Code',
            title: 'Title',
            message: 'Message',
            suggestion: 'Suggestion',
        },
        SUGGESTIONS: {
            enableCookie:
                "This error occurs when the browser's third-party cookies setting is disabled." +
                ' This application requires third-party cookies to be enabled for authentication.' +
                ' Enable the third-party cookie in your browser settings and refresh the page.',
            invalidState:
                'You might be trying to access the application in the Firefox browser using a private' +
                ' session. Try refreshing the browser to access the application.',
            invalidAccess:
                'The account you are using to log in does not have access to the application. If you did not ' +
                'enter any credentials and see this error, then a Microsoft account is active in the browser session ' +
                'that is being used to access this application. Log out of the active Microsoft account, or access the ' +
                'application in the browser’s private or incognito mode.',
        },
        tenantDoesNotExistTitle: 'Tenant does not exist',
        unauthorized: 'Unauthorized'
    }
};
