import { useState, useEffect } from 'react';
import { ErrorToaster } from './../components/toast';

export function useLoading<T>(promiseOrFunction: (() => Promise<T>) | Promise<T>): [boolean] {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        let valid = true;
        const promise = typeof promiseOrFunction === "function" ? promiseOrFunction() : promiseOrFunction;

        promise.then(() => {
                if (valid) {
                    setLoading(false);
                }
            }, (error) => {
                //handle error here
                if (valid) {
                    //data stays undefined
                    ErrorToaster({
                        message: error.message
                    });
                    setLoading(false);
                }
            });

        return () => {
            //cleanup, this will avoid setState on unmounted component
            valid = false;
        };
    }, [promiseOrFunction]);

    return [loading];
}
