import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { STRINGS } from '../../strings';
import { Modal } from './Modal';
import { DateRange, DateRangePicker, IDateRangePickerProps } from '@blueprintjs/datetime';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

const defaultDateRangePickerProps: IDateRangePickerProps = {
    allowSingleDayRange: true,
    contiguousCalendarMonths: false,
    shortcuts: true,
    minDate: moment('2020-01-01').startOf('day').toDate(),
};

/**
 * this is needed because the component does not support setting the timezone,
 * so we need to perform this hacky conversions back and forth
 *
 * only possible other solution is to pick another component that supports timezone
 *
 * both functions are based on the concept oof transforming the date in an ambiguous (no TZ) representatin
 * and then parsing it back as either utc or local
 */

const LocalToUtc = (d: moment.Moment) => {
    return moment.utc(d.format('YYYY-MM-DDTHH:mm:ss'));
};

const UtcToLocal = (d: moment.Moment) => {
    return moment(d.format('YYYY-MM-DDTHH:mm:ss'));
};

//we want to reuse all the properties of the inner component but expose a moment interface instead
type DateRangePickerModalProps = Omit<IDateRangePickerProps, "defaultValue">;

const handleClose = (closeFn?: Function) => {
    if (closeFn) {
        closeFn();
    }
};

const translateDefaultValue = (dr: [moment.Moment, moment.Moment] | undefined, utc = true) => {
    let ret: DateRange;
    if (dr !== undefined) {
        if (utc === false) {
            // @ts-ignore   there is null check, but it does not seem to pick it
            ret = [dr[0].toDate(), dr[1].toDate()];
        } else {
            ret = [UtcToLocal(dr[0]).toDate(), UtcToLocal(dr[1]).toDate()];
        }
        return ret;
    }

};

//
/**
 * the component returns moment dates!
 *
 * check if utcmode is needed in this case!
 *
 * utcTimezone = true or not set will return the unix timestamp for the dates
 * utcTimezone = false (explicit) with just return the two date objects
 */
function DateRangePickerModal (props: DateRangePickerModalProps & { utcTimezone: boolean, onSubmit: (dateRange) => void, defaultValue?: [moment.Moment, moment.Moment], onClose?: Function}) {

    const { utcTimezone, onSubmit, defaultValue, ...dateRangePickerProps } = props;
    const [dateRange, setDateRange] = useState(translateDefaultValue(defaultValue));

    const handleSubmit = useCallback(() => {
        if (!dateRange || (!(dateRange[0] instanceof Date) && !(dateRange[1] instanceof Date))) {
            throw new Error(STRINGS.DATE_TIME_PICKER_MODAL.errors.daterange_invalid);
        } else {
            const start = (dateRange[0] ? moment(dateRange[0]) : moment(dateRange[1])).startOf('day'); // covering the case when one date selected
            const end = (dateRange[1] ? moment(dateRange[1]) : moment(start)).endOf('day');
            if (utcTimezone === false) { //unless we really don't want utc mode
                onSubmit([start, end]);
            } else {
                //do sketchy conversion to ignore timezone concept from the date
                onSubmit([LocalToUtc(start), LocalToUtc(end)]);
            }

        }
    }, [onSubmit, utcTimezone, dateRange]);

    return (
        <Modal onSubmit={handleSubmit} title={STRINGS.DATE_TIME_PICKER_MODAL.title} usePortal={true} onClose={() => {handleClose(props.onClose)}}>
            <DateRangePicker
                onChange={setDateRange}
                defaultValue={dateRange}
                {...defaultDateRangePickerProps} //set defaults
                maxDate={moment.utc().endOf('day').toDate()}  //this is done here instead so it is always the end of the current day even if UI was open for long
                {...dateRangePickerProps} // override defaults
            />
        </Modal>
    );
}

export { DateRangePickerModal };