import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { Classes } from "../../common";
import './LoadingOverlay.scss';

function LoadingOverlay(props: { visible?: boolean; spinnerSize?: number, loadingText?: string }) {

    if(props.visible) {
        const spinnerSize = props.spinnerSize || Spinner.SIZE_STANDARD;

        return (
            <div className={ 'loading-overlay ' + Classes.OVERLAY_BACKDROP }>
                <Spinner size={ spinnerSize }/>
                { props.loadingText ? <div>{ props.loadingText }</div> : null }
            </div>
        );
    }

    return null;
}

export { LoadingOverlay };
