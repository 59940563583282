/**
 * This stuff is identical to the stuff in FilterableSelectField, consolidate the common stuff we basically need the version without formik to then use with formik in there
 */
import * as React from 'react';
import { MenuItem } from '@blueprintjs/core';
import { Suggest, ItemPredicate, ItemRenderer } from '@blueprintjs/select';
import "./SuggestInput.scss";

type itemType = {
    display: string;
    value: string;
};

export const filterItem: ItemPredicate<itemType> = (
    query,
    item,
    _index,
    exactMatch
) => {
    //future: allow override
    const normalizedName = item.display.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedName === normalizedQuery;
    } else {
        return normalizedName.indexOf(normalizedQuery) >= 0;
    }
};

function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    // eslint-disable-next-line no-useless-escape
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

export const renderItem: ItemRenderer<itemType> = (
    item,
    { handleClick, modifiers, query }
) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item.display}
            onClick={handleClick}
            text={highlightText(item.display, query)}
        />
    );
};

const InnerSuggestInput = Suggest.ofType<{ display: string; value: string }>();

//todo tweak this to make them optional instead of removed
//type SuggestInputProps = Exclude<ISuggestProps<itemType>, ['itemRenderer', 'itemPredicate', 'inputValueRenderer', 'noResults', 'resetOnSelect', 'popoverProps', 'closeOnSelect', 'resetOnClose']>;
//function SuggestInput (props: SuggestInputProps & ClassAttributes<any>) {
//this somehow does not seem to work, it still wants 2 of the props that were removed from that list

/**
 * this component just provides a bunch of default values to the existing Suggest component, so we can use it without having to reimplement these bits everywhere
 */
const SuggestInput = React.forwardRef((props: any, ref) => {
    return (
        <InnerSuggestInput
            // @ts-ignore
            ref={ref} //this works fine contrary to the ts complaint
            itemRenderer={renderItem}
            itemPredicate={filterItem}
            inputValueRenderer={item => {
                return item.display;
            }}
            noResults={<MenuItem disabled={true} text="No results." />}
            resetOnSelect={true}
            popoverProps={{ minimal: true }}
            closeOnSelect={true}
            resetOnClose={true}
            {...props}
        />
    );
});

export { SuggestInput };
