import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

type RoutedMenuItemProps = {
    path: any;
    title?: string;
    text?: string;
    icon?: any;
    [x: string]: any;
    className?: string;
};

function RoutedMenuItem(props: RoutedMenuItemProps) {
    const { path, title, text, icon, className } = props;

    let history = useHistory();
    let match = !!useRouteMatch(path);

    const navigateTo = useCallback((e) => {
        e.stopPropagation();
        history.push(path);
    },[history, path]);

    const classes = classNames(
        Classes.MENU_ITEM,
        Classes.ICON + "-" + icon,
        {
            [Classes.ACTIVE] : match,
            [Classes.INTENT_PRIMARY] : match
        },
        className
    );

    return (
        <li>
            <Link className={classes} to={path} onClick={navigateTo}>
                {title || text}
            </Link>
        </li>

    );

}

export { RoutedMenuItem };
