import { AuthenticationParameters } from 'msal';
import { STATIC_TENANTS, TenantMapProps } from '../tenants';

export enum TenantTypes {
  PARTNER = 'PARTNER',
  CUSTOMER = 'CUSTOMER',
}

export enum PLATFORM_ROLES {
  PORTFOLIO_ADMIN = 'role.portfolio.admin',
}

const BRULEE_ROLES = {
  ADMIN: 'role.brulee.admin',
  WRITE: 'role.brulee.write',
  READ: 'role.brulee.read',
} as const;

export type BruleeRolesType = typeof BRULEE_ROLES[keyof typeof BRULEE_ROLES];

const GELATO_ROLES = {
  ADMIN: 'role.gelato.admin',
  WRITE: 'role.gelato.write',
  READ: 'role.gelato.read',
} as const;

export type GelatoRolesType = typeof GELATO_ROLES[keyof typeof GELATO_ROLES];

export const ROLES_BY_PRODUCT = {
  GELATO: GELATO_ROLES,
  BRULEE: BRULEE_ROLES,
} as const;

export type ProductType = keyof typeof ROLES_BY_PRODUCT;

export enum AuthConstants {
  IN_PROGRESS = 'IN_PROGRESS',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  ERROR = 'ERROR',
}

type EnvironmentConfig = {
  tenantServiceLocation: string;
  permissions: (userId: string) => string;
  directoryId: string;
  apiScope: string;
  staticTenants?: TenantMapProps;
};

export type EnvironmentType = {
  perfdev: EnvironmentConfig;
  dev: EnvironmentConfig;
  staging: EnvironmentConfig;
  preprod: EnvironmentConfig;
  prodeu1: EnvironmentConfig;
  prod: EnvironmentConfig;
};

export class AuthConfig {
  static issuerUri = 'https://login.microsoftonline.com/';
  static scopes = ['User.Read'];
  static tenantUrl = window.location.origin;
  static logoutUri = `${AuthConfig.tenantUrl}/logout`;
  static authenticationParameters: AuthenticationParameters = {
    scopes: AuthConfig.scopes,
  };
}

export const REDIRECT_FROM_URI_SESSION_VARIABLE = 'tir-auth-redirect-from';

export const ENV_CONFIG: EnvironmentType = {
  perfdev: {
    /** location of the tenant service for this stage */
    tenantServiceLocation: 'https://api.moka.systems/common/public/tenant',
    /** permissions service */
    permissions: (userId: string) => `/api/common/user/${userId}/permissions`,
    /** active directory ID (constants per stage,not to be confused to tenant application ID) */
    directoryId: '8eaac28e-2daf-464b-9339-7a238427eb5f',
    /** scope path for the api, could be per store (or service) in the future */
    apiScope: 'api://e432a27a-83c6-4f81-82f0-ff3282de3717',
    staticTenants: STATIC_TENANTS['perfdev'],
  },
  dev: {
    /** location of the tenant service for this stage */
    // NOTE: old current
    tenantServiceLocation: 'https://api.moka.systems/common/public/tenant',
    /** permissions service */
    permissions: (userId: string) => `/api/common/user/${userId}/permissions`,
    // permissions: (userId: string) =>
    //   `/api/tenant-2/v2.0/common/user/${userId}/permissions`,
    /** active directory ID (constants per stage,not to be confused to tenant application ID) */
    directoryId: '8eaac28e-2daf-464b-9339-7a238427eb5f',
    /** scope path for the api, could be per store (or service) in the future */
    apiScope: 'api://e432a27a-83c6-4f81-82f0-ff3282de3717',
    staticTenants: STATIC_TENANTS['dev'],
  },
  staging: {
    tenantServiceLocation:
      'https://api.mascarpone.systems/common/public/tenant',
    /** permissions service */
    permissions: (userId: string) => `/api/common/user/${userId}/permissions`,
    directoryId: '97d28718-ab4d-405e-99c0-187b00854fa4',
    apiScope: 'api://f04a00eb-cdbd-4d41-bf9e-6f1b5383a936',
  },
  preprod: {
    tenantServiceLocation:
      'https://api.mascarpone.systems/common/public/tenant',
    /** permissions service */
    permissions: (userId: string) => `/api/common/user/${userId}/permissions`,
    directoryId: '97d28718-ab4d-405e-99c0-187b00854fa4',
    apiScope: 'api://f04a00eb-cdbd-4d41-bf9e-6f1b5383a936',
  },
  prodeu1: {
    tenantServiceLocation:
      'https://api.cloud.riverbed.com/common/public/tenant',
    /** permissions service */
    permissions: (userId: string) => `/api/common/user/${userId}/permissions`,
    directoryId: 'd89a4b48-c2d2-4bf5-88fb-22231d80ed75',
    apiScope: 'api://fcf676b3-3e99-46cf-b4a7-4739dadd8799',
  },
  prod: {
    tenantServiceLocation:
      'https://api.cloud.riverbed.com/common/public/tenant',
    /** permissions service */
    permissions: (userId: string) => `/api/common/user/${userId}/permissions`,
    directoryId: 'd89a4b48-c2d2-4bf5-88fb-22231d80ed75',
    apiScope: 'api://fcf676b3-3e99-46cf-b4a7-4739dadd8799',
  },
};
