let instance: any;

class Singleton {
  triggerDialog = (data: any) => {
    console.log(data);
  };

  constructor() {
    if (instance) {
      throw new Error('You can create only one instance');
    }
    instance = this;
  }

  setTriggerFunction(func: any) {
    this.triggerDialog = func;
  }

  notify(data: any) {
    return this.triggerDialog(data);
  }
}

export const MessageService = new Singleton();
