import * as React from 'react';
import {
    Icon as BlueprintIcon,
    IIconProps as BlueprintIconProps,
    Classes,
    AbstractPureComponent2,
    Intent
} from "@blueprintjs/core";
import classNames from "classnames";
import { ThemeManager, TIR_UI_THEME_CHANGE } from "../themes/ThemeManager";
import { IconLibrary, getIntentColor, IconName } from "./IconLibrary";
import { STRINGS } from '../strings';


const renderDesc = (title?: string | null | false) => {
    if (title) {
        return (
            <desc>
                {title}
            </desc>
        );
    } else {
        return null;
    }
};

const renderFromIconLib = (icon: string, intent?: string, title?: string, color?: string) => {
    if (!icon) {
        return null;
    } else {
        return (
            <React.Fragment>
                {renderDesc(title)}
                {IconLibrary[icon].getSvg(color || getIntentColor(intent || Intent.NONE))}
            </React.Fragment>
        );
    }
};

export type IIconProps = Omit<BlueprintIconProps, 'icon'> & {
    /** string value to specify icon names restricted to values defined in `IconNames`.
    Use `IconNames` to specify which icon you need to display. eg: `IconNames.ADD` */
    icon?: string | IconName,
    children?: React.ReactElement | undefined | null
}

/** Use the `Icon` component to display icons within the Blueprint universe
 * and the icons defined in the IconsLibrary */
export class Icon extends AbstractPureComponent2<IIconProps & React.DOMAttributes<HTMLElement>> {
    static SIZE_LARGE = BlueprintIcon.SIZE_LARGE;
    static SIZE_STANDARD = BlueprintIcon.SIZE_STANDARD;
    constructor(props: IIconProps) {
        super(props);
        this.state = {
            theme: ThemeManager.getTheme()
        };
    }

    private setTheme = () => {
        this.setState({theme: ThemeManager.getTheme()});
    }

    componentDidMount = () => {
        // register the theme change event to re-render the icons
        window.addEventListener(TIR_UI_THEME_CHANGE, this.setTheme);
        this.setState({initialized: true});
    }

    componentWillUnmount = () => {
        // unregister the listener before component is removed
        window.removeEventListener(TIR_UI_THEME_CHANGE, this.setTheme);
    }

    render = (): JSX.Element | null => {
        const { icon, children } = this.props;
        let iconToRender;
        if (children || (typeof icon === "string" && IconLibrary[icon])) {
            // Usage of children(icon definition being directly provided by the developer from the consuming app)
            // is not encourage. Display a warning in the console
            if (children) {
                console.warn(STRINGS.iconPathWarning);
            }
            const {
                className,
                color,
                htmlTitle,
                iconSize = BlueprintIcon.SIZE_STANDARD,
                intent,
                title,
                tagName = "span",
                ...htmlprops
            } = this.props;

            const classes = classNames(Classes.ICON, Classes.intentClass(intent), className);
            const pixelGridSize =
                iconSize >= BlueprintIcon.SIZE_LARGE ? BlueprintIcon.SIZE_LARGE : BlueprintIcon.SIZE_STANDARD;
            const viewBox = typeof icon === "string" && IconLibrary[icon] && IconLibrary[icon].viewBox 
                ? IconLibrary[icon].viewBox : `0 0 ${pixelGridSize} ${pixelGridSize}`;

            iconToRender = React.createElement(
                tagName,
                {
                    ...htmlprops,
                    className: classes,
                    title: htmlTitle,
                },
                <svg fill={color} width={iconSize} height={iconSize} viewBox={viewBox} preserveAspectRatio="xMidYMid">
                    { children ? children : icon ? renderFromIconLib(icon, intent, title || icon, color) : null}
                </svg>,
            );
        } else {
            iconToRender = icon;
        }
        return (<BlueprintIcon {...this.props} icon={iconToRender} />);
    }
}