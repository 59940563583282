import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
    Button,
    Classes,
    Intent,
    Position,
    Keys,
    Tag,
    Tooltip,
} from '@blueprintjs/core';
import { SuggestInput } from './../suggest-input/SuggestInput';
import {
    enumOperatorsToken,
    enumValuesToken,
    filterToken,
    StructuredFilterProps,
} from './StructuredFilterTypes';
import {
    categoryDisplay,
    categoryEnumValues,
    operatorDisplay,
    operatorEnumValues,
    getNextActiveIndex,
    isValidIndex,
    mergeDateIntoFilters,
    suggestCategoryItems,
    valueDisplay,
} from './StructuredFilterUtils';
import { DateRangeTag } from './../daterange-tag';
import { momentDateRange } from './../../utils/DateUtils';
import { STRINGS } from './../../strings';

import './StructuredFilter.scss';
import { Icon, IconNames } from "../../icons";

function StructuredFilter(props: StructuredFilterProps) {
    //props
    const {
        filterConfig,
        startingFilters = [],
        clearToStarting = false,
        startingDateRange,
        onApply,
        applyOnLoad = true,
        onChange,
        className,
        disabled,
    } = props;

    //state
    const [autoFocus, setAutoFocus] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1); //this is to track the selected one for deletions etc
    const [filterOperator, setFilterOperator] = useState();
    const [filterValues, setFilterValues] = useState(startingFilters);
    const [newCategory, setNewCategory] = useState('');
    const [dateRange, setDateRange] = useState(startingDateRange);
    const [validationError, setValidationError] = useState();
    const [validationErrorOpen, setValidationErrorOpen] = useState(false);
    const [filtersChanged, setFiltersChanged] = useState(false);

    //effects
    useEffect(() => {
        //this effect runs after the component is initialized
        setAutoFocus(true); //do not autoFocus on first render, only after actual interaction

        if (applyOnLoad) {
            //trigger an apply when the filter loads, useful if we want to use apply to trigger data fetching
            handleApply();
        }
        // doing what the check wants actually breaks our logic
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        //update the outside world when the filters changed
        //note: initial initialization is considered a filter change in this case
        if (typeof onChange === 'function') {
            onChange(mergeDateIntoFilters(filterValues, dateRange));
        }
        // doing what the check wants actually breaks our logic
        // eslint-disable-next-line
    }, [filterValues, dateRange]);

    //the references to the various input elements that will appear during interaction
    const inputRef = useRef();
    const suggestCategoryRef = useRef();
    const suggestOperatorRef = useRef();
    const suggestValueRef = useRef();

    //inner setDateRange of the dateRangeTag component, for changing the date externally
    let setTagDateRange;
    const setDateRangeRef = fn => {
        setTagDateRange = fn;
    };

    //we reuse the tag_input styling from blueprintjs
    const classes = classNames(
        Classes.INPUT,
        Classes.TAG_INPUT, //
        Classes.LARGE,
        Classes.FILL,
        {
            [Classes.DISABLED]: disabled,
            'with-apply-button': onApply,
        },
        className
    );

    /* filter state management functions */

    const addFilter = filter => {
        const validationSchema = filterConfig.find(key => key.key === filter.key)?.validationSchema;
        if (validationSchema) {
            validationSchema
                .validate(filter)
                .then(function() {
                    setValidationError(undefined);
                    setValidationErrorOpen(false);
                    setFilterValues(filterValues.concat([filter]));
                    clearNewFilter();
                })
                .catch(function(error) {
                    setValidationError(error.message);
                    setValidationErrorOpen(true);
                });
        } else {
            setFilterValues(filterValues.concat([filter]));
            clearNewFilter();
        }
        setFiltersChanged(true);
    };

    const clearNewFilter = () => {
        setNewCategory('');
        setFilterOperator(undefined);
        setValidationError(undefined);
        setValidationErrorOpen(false);
        //clear also other stuff?
    };

    /* END filter state management functions */

    /* keyboard management functions */

    const handleSuggestCategoryInputKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const { selectionEnd } = event.currentTarget;

        if (selectionEnd === 0 && filterValues.length > 0) {
            // cursor at beginning of input allows interaction with tags.
            // use selectionEnd to verify cursor position and no text selection.
            if (
                event.which === Keys.ARROW_LEFT ||
                event.which === Keys.ARROW_RIGHT
            ) {
                const nextActiveIndex = getNextActiveIndex(
                    filterValues,
                    activeIndex,
                    event.which === Keys.ARROW_RIGHT ? 1 : -1
                );
                if (nextActiveIndex !== activeIndex) {
                    event.stopPropagation();
                    setActiveIndex(nextActiveIndex);
                }
            } else if (event.which === Keys.BACKSPACE) {
                handleBackspaceToRemove(event);
            } else if (event.which === Keys.DELETE) {
                handleDeleteToRemove(event);
            }
        }
    };

    const handleSuggestValueInputKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const { selectionEnd } = event.currentTarget;

        if (event.which === Keys.BACKSPACE && selectionEnd === 0) {
            clearNewFilter();
        }
    };

    const handleInputKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const { selectionEnd, value } = event.currentTarget;

        if (event.which === Keys.ENTER && value.length > 0) {
            addFilter({
                key: newCategory,
                operator: filterOperator,
                value: value,
            });
        } else if (event.which === Keys.BACKSPACE && selectionEnd === 0) {
            clearNewFilter();
        }
    };

    const handleBackspaceToRemove = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const previousActiveIndex = activeIndex;
        // always move leftward one item (this will focus last item if nothing is focused)
        setActiveIndex(getNextActiveIndex(filterValues, activeIndex, -1));
        // delete item if there was a previous valid selection (ignore first backspace to focus last item)
        if (isValidIndex(filterValues, previousActiveIndex)) {
            event.stopPropagation();
            removeIndexFromValues(previousActiveIndex);
        }
    };

    const handleDeleteToRemove = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (isValidIndex(filterValues, activeIndex)) {
            event.stopPropagation();
            removeIndexFromValues(activeIndex);
        }
    };

    const removeIndexFromValues = (index: number) => {
        setFilterValues(filterValues.filter((_, i) => i !== index));
        setFiltersChanged(true);
    };

    /* END keyboard management functions */

    /* interaction handling functions */

    const handleRemoveTag = (event: React.MouseEvent<HTMLSpanElement>) => {
        // using data attribute to simplify callback logic -- one handler for all children
        // @ts-ignore
        const index = +event.currentTarget.parentElement.getAttribute(
            'data-tag-index'
        );
        removeIndexFromValues(index);
        event.stopPropagation();
    };

    // this properly gives focus to the right element when clicking on the whole filter
    const handleOnContainerClick = () => {
        if (suggestCategoryRef.current != null) {
            // @ts-ignore typescript does not play well with this, but this is correct
            suggestCategoryRef.current.inputElement.focus();
        } else if (suggestOperatorRef.current != null) {
            // @ts-ignore
            suggestOperatorRef.current.inputElement.focus();
        } else if (suggestValueRef.current != null) {
            // @ts-ignore
            suggestValueRef.current.inputElement.focus();
        } else if (inputRef.current != null) {
            // @ts-ignore
            inputRef.current.focus();
        }
    };

    const handleClear = event => {
        event.stopPropagation(); //avoid passing focus

        //handle the special daterange filter
        if (startingDateRange && setTagDateRange) {
            if (clearToStarting) {
                setDateRange(startingDateRange);
                setTagDateRange(startingDateRange);
            } else {
                setDateRange(undefined);
                setTagDateRange();
            }
        }

        //handle any new filter in progress
        clearNewFilter();

        //handle normal filters
        if (clearToStarting) {
            setFilterValues(startingFilters);
        } else {
            setFilterValues([]);
            setFilterOperator(undefined);
        }
        setFiltersChanged(true);
    };

    const handleOnNewDate = (dr: momentDateRange | undefined) => {
        setDateRange(dr);
        setFiltersChanged(true);
    };

    const handleApply = () => {
        // @ts-ignore  ignoring because can't happen by construction
        onApply(mergeDateIntoFilters(filterValues, dateRange));
        setFiltersChanged(false);
    };

    /* END interaction handling functions */

    /* Render functions */

    const renderTextInput = () => {
        //this is used as an uncontrolled component, since we manage the vale in the onKeyDown function
        return (
            <Tooltip
                disabled={validationError ? false : true}
                content={validationError}
                intent={Intent.DANGER}
                inheritDarkTheme={true}
                position={Position.TOP_LEFT}
                isOpen={validationErrorOpen}
                usePortal={true}
            >
                <input
                    onKeyDown={handleInputKeyDown}
                    placeholder={STRINGS.STRUCTURED_FILTER.filterValue}
                    // @ts-ignore
                    ref={inputRef} //this works fine contrary to the ts complaint
                    className={Classes.INPUT_GHOST}
                    autoFocus={true}
                    disabled={disabled}
                />
            </Tooltip>
        );
    };

    const renderSuggestCategory = () => {
        const categories = suggestCategoryItems(filterConfig, filterValues);
        if (categories.length === 0) {
            //do not show the "Add filter" input if there are no more things to add
            return null;
        }

        return (
            <SuggestInput
                key="suggest-input-category" //so react considers it a different element from the other SuggestInput
                items={categories} // works but could use passing a cleaned up version?
                inputProps={{
                    placeholder: STRINGS.STRUCTURED_FILTER.addFilter,
                    className: Classes.INPUT_GHOST,
                    autoFocus: autoFocus,
                    onKeyDown: handleSuggestCategoryInputKeyDown,
                }}
                onItemSelect={item => {
                    setNewCategory(item.key);
                }}
                ref={suggestCategoryRef}
            />
        );
    };

    const renderSuggestOperator = (operators: enumOperatorsToken[]) => {
        return (
            <SuggestInput
                key="suggest-input-operator"
                items={operators}
                inputProps={{
                    placeholder: STRINGS.STRUCTURED_FILTER.filterOperator,
                    className: Classes.INPUT_GHOST,
                    autoFocus: true,
                }}
                onItemSelect={item => {
                    setFilterOperator(item.key);
                }}
                ref={suggestOperatorRef}
            />
        );
    };

    const renderSuggestValue = (values: enumValuesToken[]) => {
        return (
            <SuggestInput
                key="suggest-input-value" //so react considers it a different element from the other SuggestInput
                items={values}
                inputProps={{
                    placeholder: STRINGS.STRUCTURED_FILTER.filterValue,
                    className: Classes.INPUT_GHOST,
                    autoFocus: true,
                    onKeyDown: handleSuggestValueInputKeyDown,
                }}
                onItemSelect={item => {
                    addFilter({
                        key: newCategory,
                        operator: filterOperator,
                        value: item.value,
                    });
                }}
                ref={suggestValueRef}
            />
        );
    };

    const renderFilterInput = () => {
        //if a category was chosen already
        if (newCategory) {
            if (!filterOperator) {
                const operators = operatorEnumValues(filterConfig, newCategory);
                if (operators) {
                    return renderSuggestOperator(operators);
                }
            } else {
                const values = categoryEnumValues(filterConfig, newCategory);
                if (values) {
                    return renderSuggestValue(values);
                } else {
                    //or a generic input
                    return renderTextInput();
                }
            }
        }
        //render the category picker
        return renderSuggestCategory();
    };

    const renderComposingFilter = () => {
        //operator can be ignored for now since we only have equality
        if (newCategory) {
            return (
                <span className="composing-filter-category">
                    <b>{categoryDisplay(filterConfig, newCategory)}</b>&nbsp;
                    <i>
                        {operatorDisplay(filterConfig, {
                            key: newCategory,
                            operator: filterOperator,
                            value: '',
                        })}
                    </i>
                </span>
            );
        }

        return null;
    };

    const renderFilterToken = (token: filterToken, index: number) => {
        //ignoring operator for now but should be added here when handling multiple
        return (
            <Tag
                active={index === activeIndex}
                data-tag-index={index}
                key={token + '-' + index}
                large={true}
                onRemove={disabled ? undefined : handleRemoveTag}
                minimal={true}
                round={true}
            >
                <b>{categoryDisplay(filterConfig, token.key)}</b>&nbsp;
                <i>{operatorDisplay(filterConfig, token)}</i>&nbsp;
                {valueDisplay(filterConfig, token)}
            </Tag>
        );
    };

    const renderDateRangeTag = () => {
        if (startingDateRange) {
            return (
                <DateRangeTag
                    defaultValue={startingDateRange}
                    setDateRangeRef={setDateRangeRef}
                    onNewDate={handleOnNewDate}
                />
            );
        }

        return null;
    };

    return (
        <React.Fragment>
            <div className="tir-ui-structured-filter">
                <div className={classes} onClick={handleOnContainerClick}>
                    <Icon
                        className={Classes.TAG_INPUT_ICON}
                        icon={IconNames.SEARCH}
                        iconSize={Icon.SIZE_LARGE}
                    />
                    <div className={Classes.TAG_INPUT_VALUES}>
                        {renderDateRangeTag()}
                        {filterValues.map(renderFilterToken)}
                        {renderComposingFilter()}
                        {renderFilterInput()}
                    </div>
                    <Button
                        icon={
                            clearToStarting ? IconNames.RESET : IconNames.CROSS
                        }
                        title={
                            clearToStarting
                                ? STRINGS.STRUCTURED_FILTER.resetFilter
                                : STRINGS.STRUCTURED_FILTER.clearFilter
                        }
                        minimal={true}
                        onClick={handleClear}
                    />
                </div>
                {onApply && filtersChanged ? (
                    <Button
                        intent={Intent.PRIMARY}
                        className="apply-button"
                        onClick={handleApply}
                    >
                        {STRINGS.apply}
                    </Button>
                ) : null}
            </div>
        </React.Fragment>
    );
}

export { StructuredFilter };
