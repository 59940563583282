import React from 'react';
import { TableColumnDef } from './Table';
import { Button, Checkbox } from '@blueprintjs/core';
import { Icon, IconNames } from '../../icons';

/** an interface that describes the properties that can be passed in to the table header component.*/
export type THeadProps = {
    headerGroups: any,
    getHeaderProps?: (column: TableColumnDef) => Record<string, string>,
    getColumnProps?: (column: TableColumnDef) => Record<string, string>,
    getRowProps?: (column: TableColumnDef) => Record<string, string>,
    enableSorting?: boolean,
    showFilterRow?: boolean,
    enableSelection?: boolean,
    showSelectionControl?: boolean,
    maxSelectionCount?: number,
    isAllRowsSelected?: boolean,
    toggleAllRowsSelected?: (set?: Boolean) => void,
    hasExpandableRows?: boolean,
    showExpandAllControl?: boolean,
    isAllRowsExpanded?: boolean,
    toggleAllRowsExpanded?: (set?: Boolean) => void,
    /** a callback for header click events. */
    onHeaderClick?: (event: any, column: any) => void
}

/** Renders the table header react component.
 *  @param props the properties passed in.
 *  @returns the JSX with the table header. */
 export const THead = ({
    headerGroups,
    getHeaderProps = () => ({}),
    getColumnProps = () => ({}),
    enableSorting,
    showFilterRow,
    enableSelection,
    showSelectionControl,
    maxSelectionCount = 0,
    isAllRowsSelected,
    toggleAllRowsSelected,
    hasExpandableRows,
    showExpandAllControl,
    isAllRowsExpanded,
    toggleAllRowsExpanded,
    onHeaderClick,
 }: THeadProps): JSX.Element => {
 
    // Render the Header Contents
    function renderHeaderContentForColumn (column) {
        return <>
            { column.render('Header') }
            {
                // Render Sort Indicators if applicable
                enableSorting && column.defaultCanSort && <Button
                    icon={<Icon icon={
                        column.isSorted ?
                        (column.isSortedDesc ? IconNames.CARET_DOWN : IconNames.CARET_UP) :
                        IconNames.DOUBLE_CARET_VERTICAL
                    }/>}
                    className={"sort-control align-middle" + (column.isSorted ? (column.isSortedDesc ? " sorted-desc" : " sorted-asc") : " no-sort show-on-hover")}
                    minimal={true}
                    title="sort"
                />
            }
        </>;
    }
    return (<>
        <thead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                        hasExpandableRows &&
                        <th className="expand-row-column">
                            {
                                showExpandAllControl && <Icon
                                    data-testid="expand-all-control"
                                    icon={isAllRowsExpanded ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_RIGHT}
                                    onClick={e => {
                                        e.preventDefault();
                                        if (toggleAllRowsExpanded) {
                                            toggleAllRowsExpanded();
                                        }
                                    }}
                                />
                            }
                        </th>
                    }
                    {
                        enableSelection && showSelectionControl &&
                        <th className="select-row-column">
                            {
                                maxSelectionCount < 1 && 
                                <Checkbox
                                    checked={isAllRowsSelected}
                                    onClick={(e) => {
                                        if (toggleAllRowsSelected) {
                                            toggleAllRowsSelected(e.currentTarget.checked);
                                        }
                                    }}
                                    data-testid="toggle-all-selection"
                                />
                            }
                        </th>
                    }
                    {
                        // Loop over the headers in each row
                        headerGroup.headers.map(column => { 
                            const toggleProps = column.getSortByToggleProps();
                            const clickHandler = (e) => {
                                toggleProps.onClick(e);
                                if (onHeaderClick) {
                                    onHeaderClick(e, column);
                                }
                            };
                            // Apply the header cell props
                            return <th
                                {...column.getHeaderProps([
                                    {
                                        className: column.headerClassName,
                                        style: column.style,
                                    },
                                    getColumnProps(column),
                                    getHeaderProps(column),
                                ])}
                                {...(enableSorting && column.defaultCanSort ? column.getHeaderProps({...toggleProps, onClick: clickHandler}) : {})}
                            >{
                                showFilterRow === false && column.showFilter ?
                                column.render("Filter", {
                                    key: "filter-" + column.id,
                                    placeholder: column.filterPlaceHolder,
                                    canToggle: !column.alwaysShowFilter,
                                    children: renderHeaderContentForColumn(column),
                                    ...column.filterProps
                                }) :
                                renderHeaderContentForColumn(column)
                            }</th>;
                        })
                    }
                </tr>
            ))}
        </thead>
        {showFilterRow &&
            <thead key="filter-row-header">{
            headerGroups.map((headerGroup, index) => (
                <tr data-testid="filter-controls-row" key={"filter-controls-row-" + index} className="filter-row">
                    { hasExpandableRows && <th className="expand-row-column"/> }
                    {
                        headerGroup.headers.map(column => (
                            <th key={"filter-controls-cell-" + column.id}>{
                                column.canFilter && column.showFilter ? column.render("Filter", { key: "filter-" + column.id, placeholder: column.filterPlaceHolder, canToggle: false, ...column.filterProps }) : ""
                            }</th>
                        ))
                    }
                </tr>
            ))
            }</thead>
        }
    </>);
};
