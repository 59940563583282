import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table, TableColumnDef } from './Table';
import { useStateSafePromise } from '../../hooks';

type ServerTableProps = {
    columns: TableColumnDef[];
    fetchFn: Function;
    pageSizeOptions?: number[];
    fetchOnLoad?: boolean;
    [index: string]: any;
};

// should be customizable since different apis might have different params for the same concept
const makePaginationParams = (pageIndex = 0, pageSize) => {
    return { limit: pageSize, offset: pageIndex * pageSize };
};

// should be customizable since different apis might have different params for the same concept
const calculateTotalPages = (response, pageSize) => {
    return Math.ceil(response.meta.total / pageSize);
};

const DEFAULT_PAGE_SIZE_OPTIONS = [20, 40, 60]; //the SMC apis currently allow minimum 20, tweak this in the future

export const ServerTable = (props: ServerTableProps) => {
    const {
        fetchFn,
        fetchOnLoad = true,
        pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
        ...tableProps
    } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [pageCount, setPageCount] = useState(0);
    const [executeSafely] = useStateSafePromise();

    const [queryParams, setQueryParams] = useState({ limit: 20, offset: 0 }); //todo this is quite wrong! fix

    const init = useRef(true);

    //this will realistically trigger only when queryParams OR fetchFn change
    const _fetchFn = useCallback(() => {
        /*  ideally I would like to debounce this here as to avoid any trouble from multiple calls from outside
            but I cannot understand how to properly debounce this, given that it will always get created new
         */
        setLoading(true);
        setError(undefined);
        executeSafely(fetchFn(queryParams)).then(
            response => {
                setLoading(false);
                // @ts-ignore
                setData(response.data); //this assumes all apis will return "data" which is wrong, this component will require improvement
                setPageCount(calculateTotalPages(response, queryParams.limit)); //todo this is bad, but what options are there? function that fetches it? vs duplicating the state here
            },
            error => {
                setLoading(false);
                setData([]);
                setError(error.message);
            }
        );
    }, [
        fetchFn,
        setLoading,
        setData,
        setPageCount,
        queryParams,
        executeSafely,
    ]);

    useEffect(() => {
        if (init.current && !fetchOnLoad) {
            return;
        }
        _fetchFn();
    }, [_fetchFn, fetchOnLoad]);

    //this has to be the last effect, so all the effects are skipped on first init
    useEffect(() => {
        init.current = false;
    }, []);

    //todo in the future we will need to handle other events like sort etc
    const handleOnPaginationChange = useCallback(
        pagination => {
            //todo user query merge function instead when we have more stuff like sort
            setQueryParams(
                makePaginationParams(pagination.pageIndex, pagination.pageSize)
            );
        },
        [setQueryParams]
    );

    return (
        <Table
            data={data}
            useTableParams={{ pageCount: pageCount, manualPagination: true }}
            loading={loading}
            onPaginationChange={handleOnPaginationChange}
            pageSizeOptions={pageSizeOptions}
            noDataText={error}
            {...tableProps}
        />
    );
};
