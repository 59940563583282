import { useCallback } from 'react';
import { useIsMounted } from './useIsMounted';

export function useStateSafePromise () {
    const [isMounted] = useIsMounted();

    return [useCallback(
        <T>(promiseOrFunction: (() => Promise<T>) | Promise<T>, onCancel?: () => void) => new Promise<T>((resolve, reject) => {
            const promise = typeof promiseOrFunction === "function" ? promiseOrFunction() : promiseOrFunction;
            promise
                .then((result) => {
                    if (isMounted()) {
                        resolve(result);
                    }
                })
                .catch((error) => {
                    if (isMounted()) {
                        reject(error);
                    }
                })
                .finally(() => {
                    if (!isMounted() && onCancel) {
                        onCancel();
                    }
                });
        }),
        [isMounted],
    )];
}
