import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import hc_exporting from 'highcharts/modules/exporting';
import { Chart } from './Chart';
import { STRINGS } from '../../strings';
import { DateRangePickerModal } from '../modal/DateRangePickerModal';
import './DateRangeChart.scss';

hc_exporting(Highcharts);

const makeSubtitle = (dateRange: [moment.Moment, moment.Moment]) => {
    let subTitleText;
    if (dateRange[0].isSame(dateRange[1], 'day')) {
        subTitleText = dateRange[1].format('MMMM D, YYYY');
    } else {
        subTitleText = dateRange[0].format('MMMM D, YYYY') + ' - ' + dateRange[1].format('MMMM D, YYYY');
    }

    return { text: subTitleText }
};

const renderModal = (modalProps: any, clearProps?: Function) => {
    if (modalProps.hasOwnProperty('utcTimezone')) {
        //pass clearProps function to on close of modal which will clear the properties of DateRangePickerModal to
        // remove the component from DOM. If we dont clear the props the modal will close but wont be removed from
        // ReactDom and next time you wont see the modal
        return (<DateRangePickerModal {...modalProps} onClose={clearProps}/>)
    } else {
        return null;
    }
};

/**
 * This is a version of the chart that handles a date range selection
 */
function DateRangeChart (props: HighchartsReact.Props & { onDateRangeChange: (dr) => void, defaultDateRange }) {

    const { options, onDateRangeChange, defaultDateRange, ...chartProps } = props;
    const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
    const [dateRange, setDateRange] = React.useState(defaultDateRange);
    const [modalProps, setModalProps] = React.useState({});
    useEffect(() => {
        const showDateRangeModal = () => {
            setModalProps({
                utcTimezone: true,
                defaultValue: dateRange,
                onSubmit: (dr) => {
                    setDateRange(dr);
                    onDateRangeChange(dr);
                },
            });
        };

        //declared here because it has dependencies on state functions
        const baseChartOptions: Highcharts.Options = {
            subtitle: makeSubtitle(dateRange),
            exporting: {
                enabled: true,
                menuItemDefinitions: {
                    dateRange: {
                        onclick: function () {
                            showDateRangeModal();
                        },
                        text: STRINGS.selectDateRange
                    },
                },
                buttons: {
                    contextButton: {
                        menuItems: ['dateRange'],
                    },
                },
            },
            xAxis: {
                type: 'datetime'
            },
        };

        //set the base options
        setChartOptions(Highcharts.merge(baseChartOptions, options));

    }, [options, dateRange, onDateRangeChange]);

    return (
        <div className="date-range-chart">
            <Chart
                highcharts={Highcharts}
                options={chartOptions}
                {...chartProps}
            />
            {
                renderModal(modalProps, () => {
                    setModalProps({});
                })
            }

        </div>

    );
}

export { DateRangeChart };
