import React from 'react';
import { Card, H4, ICardProps } from '@blueprintjs/core';
import classNames from 'classnames';
import { LoadingOverlay } from '../loading/LoadingOverlay';
import './Widgets.scss';


type WidgetProps = {
    id: string,
    children: React.ReactNode,
    loading?: boolean,
    title?:string,
    error?: string
} & ICardProps

//todo add correct generic styling for widgets
function Widget(props: WidgetProps) {

    const { id, children, loading, title, error, ...iCardProps } = props;

    const contentClassName =  classNames('widget-content', { 'has-title': props.title });
    const widgetClassNames = classNames ('widget', props.className)
    return (
        <Card data-testid={id} className={widgetClassNames} {...iCardProps}>
            <LoadingOverlay visible={loading} />
            {title ? <H4 className='widget-title'>{title}</H4> : null }
            <div className={contentClassName}>{ error ? <div className="error">{error}</div> : children }</div>
        </Card>
    );
}

export { Widget };
