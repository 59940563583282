import React, { useCallback, useState } from 'react';
import * as Highcharts from 'highcharts';
import classNames from 'classnames';
import { Chart } from './Chart';
import { Classes } from './../../common';
import { LoadingOverlay } from '../loading/LoadingOverlay';
import { useLoading, useStateSafePromise } from './../../hooks';

import './ServerChart.scss';

type ServerChartProps = {
    chartOptions?: Highcharts.Options,
    fetchFunction: ()=>Promise<Highcharts.Options>
}

export const ServerChart = (props: ServerChartProps) => {

    const {fetchFunction, chartOptions} = props;
    const [_chartOptions, setChartOptions] = useState(chartOptions);
    const [error, setError] = useState(undefined);
    const [executeSafely] = useStateSafePromise();

    const fetchData = useCallback(() => {
        return executeSafely(fetchFunction).then(
            chartOptions => {
                setChartOptions(chartOptions);
            },
            error => {
                setError(error.message);
            }
        );
    }, [fetchFunction, executeSafely]);

    const [loading] = useLoading(fetchData);

    const className = classNames('tir-ui-server-chart', Classes.LOADING_RELATIVE);

    return (
        <div className={className}>
            <LoadingOverlay visible={loading}/>
            { error ? <div className="error">{error}</div> : <Chart options={_chartOptions}/> }
        </div>
    )
};
