import React from 'react';

import './PropertyList.scss';

type PropertyListProps = {
    id: string,
    children: React.ReactNode
}

function PropertyList(props: PropertyListProps) {

    return (
        <div data-testid={props.id} className="property-list">
            {props.children}
        </div>
    );
}

export { PropertyList };
