import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Popover, Position, Tag } from '@blueprintjs/core';
import {
    DateRange,
    DateRangePicker,
    IDateRangePickerProps,
} from '@blueprintjs/datetime';
import {
    blueprintDateRangeToMomentDateRange,
    defaultDateRangePickerProps,
    momentDateRange,
    momentDateRangeToBlueprintDateRange,
} from './../../utils/DateUtils';
import { STRINGS } from './../../strings';
import { Icon, IconNames } from "../../icons";

const dateTagText = (dateRange: [moment.Moment, moment.Moment] | undefined) => {
    if (typeof dateRange === 'undefined') {
        return (
            <React.Fragment>
                {STRINGS.DATE_RANGE_TAG.startDate} <Icon icon="arrow-right" />{' '}
                {STRINGS.DATE_RANGE_TAG.endDate}
            </React.Fragment>
        );
    }
    if (dateRange[0].isSame(dateRange[1], 'day')) {
        return dateRange[1].format('MMMM D, YYYY');
    } else {
        return (
            <React.Fragment>
                {dateRange[0].format('MMMM D, YYYY')}{' '}
                <Icon icon="arrow-right" />{' '}
                {dateRange[1].format('MMMM D, YYYY')}
            </React.Fragment>
        );
    }
};

//we want to reuse all the properties of the inner component but expose a moment interface instead
type DateRangeTagProps = Omit<IDateRangePickerProps, 'defaultValue'> & {
    utcTimezone?: boolean;
    onChange?: (dateRange: momentDateRange | undefined) => void;
    defaultValue?: momentDateRange;
    onNewDate?: (dateRange: momentDateRange | undefined) => void;
    setDateRangeRef?: (refFn: any) => void;
};

function DateRangeTag(props: DateRangeTagProps) {
    const {
        utcTimezone = true,
        onNewDate,
        onChange,
        defaultValue,
        setDateRangeRef,
        ...dateRangePickerProps
    } = props;

    const [dateRange, setDateRange] = useState(defaultValue);
    const [previousDateRange, setPreviousDateRange] = useState(defaultValue);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    useEffect(() => {
        if (typeof setDateRangeRef !== 'undefined') {
            setDateRangeRef(setDateRange);
        }
    }, [setDateRangeRef, setDateRange]);

    const handleOnDateChanged = (dr: DateRange) => {
        setDateRange(blueprintDateRangeToMomentDateRange(dr, utcTimezone));
        if (onChange) {
            onChange(dateRange);
        }
    };

    const handleOnPopoverClose = () => {
        if (typeof dateRange === 'undefined') {
            //consider this an UNDO case
            setDateRange(previousDateRange);
        } else if (onNewDate) {
            onNewDate(dateRange);
        }
    };

    const handleOnTagClick = e => {
        e.stopPropagation();
        //since popover is inside tag for layout reasons, we have to skip all the events that happen when the popover is open
        if (!isPopoverOpen) {
            setIsPopoverOpen(true);
            setPreviousDateRange(dateRange);
        }
    };

    const popoverContent = (
        <DateRangePicker
            onChange={handleOnDateChanged}
            defaultValue={momentDateRangeToBlueprintDateRange(
                dateRange,
                utcTimezone
            )}
            {...defaultDateRangePickerProps} //set defaults
            maxDate={moment
                .utc()
                .endOf('day')
                .toDate()} //this is done here instead so it is always the end of the current day even if UI was open for long
            {...dateRangePickerProps} // override defaults
        />
    );

    return (
        <Tag
            large={true}
            minimal={true}
            round={true}
            icon={IconNames.CALENDAR}
            rightIcon={IconNames.EDIT}
            interactive={true}
            onClick={handleOnTagClick}
        >
            <Popover
                isOpen={isPopoverOpen}
                autoFocus={false}
                content={popoverContent}
                enforceFocus={false}
                position={Position.BOTTOM}
                onClose={() => setIsPopoverOpen(false)} // this one actually fires twice
                onClosing={handleOnPopoverClose} // this fires once instead, so we used like the normal onClose
            >
                {dateTagText(dateRange)}
            </Popover>
        </Tag>
    );
}

export { DateRangeTag };
