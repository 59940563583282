import React from 'react';
import { Button, ButtonGroup, MenuItem } from '@blueprintjs/core';
import { IconNames, IconName, Icon } from '../../icons';
import { DropdownMenu } from './DropdownMenu';

type menuActionType = {
    /** Title of the menu item */
    title: React.ReactNode,
    /** Icon of the menu item (Optional) */
    icon?: IconName,
    /** Callback fired when clicked */
    action: Function,
};

/** Actions Menu component is a button-like menu instance with title, icon and action props. */
function ActionsMenu(props: {
    /** menu props (Optional) */
    actions?: menuActionType[];
    /** extra menu props (Optional) */
    extraActions?: menuActionType[];
}) {

    let actions: JSX.Element[] = [];
    if (props.actions) {
        actions = props.actions.map((a, i) =>
            <Button key={i} icon={<Icon icon={a.icon}/>} onClick={() => {
                a.action();
            }}>
                {a.title}
            </Button>);
    }

    let extraActions: JSX.Element[] = [];
    if (props.extraActions) {
        extraActions = props.extraActions.map((a, i) =>
            <MenuItem key={i} icon={<Icon icon={a.icon}/>} onClick={() => {
                a.action();
            }} text={a.title}/>
        );
    }

    return (
        <div className="action-menu">
            <ButtonGroup>
                {actions}
                {extraActions.length ?
                    <DropdownMenu icon={IconNames.CHEVRON_DOWN}>
                        {extraActions}
                    </DropdownMenu>
                    : null}
            </ButtonGroup>
        </div>
    );

}

export { ActionsMenu, menuActionType };
