import React from 'react';

type PropertyProps = {
    id: string,
    label: string,
    value?: string,
    children?: any
}

function Property(props: PropertyProps) {

    return (
        <React.Fragment>
            <div data-testid={props.id + "-label"} className="property-label">{props.label}:</div>
            <div data-testid={props.id + "-value"} className="property-value">{props.value || props.children}</div>
        </React.Fragment>
    );
}

export { Property };
