import React from 'react';

import './LinkButton.scss';

function LinkButton(props: any) {

    return (
        <button className="tir-ui-link-button-component" {...props}>
            {props.children}
        </button>
    );

}

export { LinkButton };
