import React from 'react';
import DOMPurify from 'dompurify';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';

export interface TopPageLevelHelpProps {
	comment?: string;
	helpSummary?: string;
	showMore?: boolean;
	url?: string;
	onHelpAction?: (action, value) => void;
}

const PageTopLevelHelp = ({
	comment = '',
	helpSummary = '',
	showMore = false,
	url = '',
	onHelpAction,
}: TopPageLevelHelpProps) => {
	const content = (
		<div key="help-content" className="d-inline-flex">
			<div style={{ width: '450px' }} className="p-2">
				<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(helpSummary)}}></span>{' '}
				{showMore && (
					<Button
						minimal
						icon={IconNames.MORE}
						onClick={(event) => {
							window.open(url, '_blank');
							event.preventDefault();
							if (onHelpAction) {
								onHelpAction("launch", url);
							}
						}}
						tabIndex={0}
					/>
				)}
			</div>
		</div>
	);

	return (
		<React.Fragment>
			<Popover2
				content={content}
				usePortal={true}
				interactionKind="click"
				placement="auto"
				fill={true}
				shouldReturnFocusOnClose={true}
				onOpened={() => {
					if (onHelpAction) {
						onHelpAction("popup", url);
					}
				}}
			>
				<div data-toggle="tooltip" title={comment}>
					<Button minimal icon={IconNames.HELP} tabIndex={0} />
				</div>
			</Popover2>
		</React.Fragment>
	);
};

export { PageTopLevelHelp };
