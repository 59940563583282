import React from 'react';
import { Button, IButtonProps } from '@blueprintjs/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconNames } from '../../icons';
import { STRINGS } from '../../strings';


function CopyTextToClipboard(props: IButtonProps & { title?: string, copyText: string, children?: React.ReactNode }) {
    // Destructuring copyText from props as its not a valid property on the button
    const { copyText, children, ...buttonProps } = props;
    return (
        <CopyToClipboard text={copyText}>
            <Button
                icon={IconNames.DUPLICATE}
                small={true}
                title={STRINGS.copy}
                {...buttonProps}
            >
                {children}
            </Button>
        </CopyToClipboard>);
}

export { CopyTextToClipboard };
