import { Classes } from '@blueprintjs/core';

const TIR_UI_THEME_LOCALSTORAGE_VARIABLE = 'tir-ui-theme';
export const TIR_UI_THEME_CHANGE = 'tir-ui-theme-change';

export enum Theme {
    DARK='dark',
    LIGHT='light'
}

class ThemeManager {
    private static theme: string | undefined;

    static init () {
        const savedTheme = localStorage.getItem(TIR_UI_THEME_LOCALSTORAGE_VARIABLE);
        const theme = savedTheme === null || undefined || 'undefined' ? 'light' : savedTheme;
        ThemeManager.setTheme(theme);
        return theme;
    }

    static toggleTheme() {
        const theme = ThemeManager.theme === 'light' ? 'dark' : 'light';
        ThemeManager.setTheme(theme);
        return theme;
    }

    static getTheme() {
        return ThemeManager.theme;
    }

    static setTheme(theme: string) {
        ThemeManager.theme = theme;
        localStorage.setItem(TIR_UI_THEME_LOCALSTORAGE_VARIABLE, theme);
        window.dispatchEvent(new Event(TIR_UI_THEME_CHANGE));
        //tried applying to a specific element but it turns out it does not get picked up as existing reliably, only body is outside react
        //better approach would be to get rid of themeManager and have the theme as state in the mainlayout
        document.body.className = theme === 'dark' ? Classes.DARK : '';
    }

}

export { ThemeManager };
