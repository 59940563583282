import React from 'react';
import { TableColumnDef } from './Table';
import {
    filterConfigToken,
    filterToken,
} from './../structured-filter/StructuredFilterTypes';
import { momentDateRange } from './../../utils/DateUtils';
import { StructuredFilter } from './../structured-filter/StructuredFilter';

export type FilterableTableColumnDef = TableColumnDef & {
    filterConfig?: Omit<filterConfigToken, 'key' | 'display'>;
};

export type TableFilterProps = {
    filterConfig: filterConfigToken[];
    startingFilters?: filterToken[];
    clearToStarting?: boolean;
    startingDateRange?: momentDateRange;
    onApply?: Function;
    onChange?: Function;
};

export const makeInnerColumnsAndFilterDef: (
    columns: FilterableTableColumnDef[],
    filterConfig: filterConfigToken[]
) => [TableColumnDef[], filterConfigToken[]] = (
    columns: FilterableTableColumnDef[],
    filterConfig: filterConfigToken[]
) => {
    const _filterConfig: filterConfigToken[] = [];
    const _columns: TableColumnDef[] = columns.map(c => {
        const { filterConfig, ...columnDef } = c;

        if (filterConfig) {
            // @ts-ignore   //can ignore this because the table will just not work if accessor is not a string and there is no id
            _filterConfig.push({ key: c.id || c.accessor, display: c.Header, ...filterConfig });
        }

        return columnDef;
    });

    return [_columns, _filterConfig.concat(filterConfig)];
};

const processFilters = filters => {
    return filters.reduce(function(p, filter: filterToken) {
        //todo support not unique
        p[filter.key] = filter;
        return p;
    }, {});
};

export const TableFilter = (props: TableFilterProps) => {
    const { onApply, onChange, ...filterProps } = props;

    const handleApply = onApply
        ? filters => {
              onApply(processFilters(filters));
          }
        : undefined;

    const handleChange = onChange
        ? filters => {
              onChange(processFilters(filters));
          }
        : undefined;

    return (
        <StructuredFilter
            onApply={handleApply}
            onChange={handleChange}
            applyOnLoad={typeof onApply !== 'undefined'}
            {...filterProps}
        />
    );
};
