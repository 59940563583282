import React, { useCallback } from 'react';
import { Tabs as BlueprintTabs, TabId, ITabsProps } from '@blueprintjs/core';
import { useHistory, useRouteMatch } from 'react-router-dom';

import './Tabs.scss';

function Tabs(props: ITabsProps & { basePath?: string, children: React.ReactNode }) {

    const { basePath, children, ...tabProps } = props;
    const _basePath = basePath ? basePath + '/' : undefined;
    const history = useHistory();
    const match = useRouteMatch(_basePath + ':tabId');
    let startTab: TabId | undefined = undefined;
    if(match) {
        startTab = match.params.tabId;
    }

    const handleTabSwitch = useCallback((newTabId: TabId, prevTabId: TabId, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if(props.onChange) {
            props.onChange(newTabId, prevTabId, event);
        }
        if(_basePath) {
            history.push(_basePath + newTabId);
        }
    }, [_basePath, history]);

    return (
        <BlueprintTabs
            className="tabs-container"
            onChange={ handleTabSwitch }
            defaultSelectedTabId={ startTab }
            { ...tabProps }
        >
            { props.children }
        </BlueprintTabs>
    );
}

export { Tabs };
