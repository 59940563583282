import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Highcharts from 'highcharts';
import { ServerChart } from './ServerChart';
import { momentDateRange } from './../../utils/DateUtils';
import { StructuredFilter } from './../structured-filter/StructuredFilter';
import {
    filterConfigToken,
    filterToken,
} from './../structured-filter/StructuredFilterTypes';

type FilterableChartProps = {
    chartOptions?: Highcharts.Options;
    fetchFunction: (params) => Promise<Highcharts.Options>;
    fetchOnLoad?: boolean;
    filterConfig?: filterConfigToken[];
    startingFilters?: filterToken[];
    clearToStarting?: boolean;
    startingDateRange?: momentDateRange;
};

export const FilterableChart = (props: FilterableChartProps) => {
    const {
        chartOptions,
        startingDateRange,
        filterConfig = [],
        startingFilters,
        clearToStarting,
        fetchFunction,
    } = props;

    const [filters, setFilters] = useState([]);

    const init = useRef(true);

    const onFiltersApplied = filters => {
        setFilters(filters);
    };

    const _fetchFn = useCallback(() => {
        const filter_params = filters.reduce(function(p, filter: filterToken) {
            p[filter.key] = filter.value; //a, b, c
            return p;
        }, {});

        return fetchFunction(filter_params);
    }, [filters, fetchFunction]);

    //this has to be the last effect, so all the effects are skipped on first init
    useEffect(() => {
        init.current = false;
    }, []);

    return (
        <React.Fragment>
            <StructuredFilter
                filterConfig={filterConfig}
                startingFilters={startingFilters}
                clearToStarting={clearToStarting}
                startingDateRange={startingDateRange}
                onApply={onFiltersApplied}
            />
            {init.current ? null : (
                <ServerChart
                    fetchFunction={_fetchFn}
                    chartOptions={chartOptions}
                />
            )}
        </React.Fragment>
    );
};
