import React, { useCallback, useState } from 'react';
import { useLoading, useStateSafePromise } from './../../hooks';
import { Widget } from './Widget';

import './SingleValueWidget.scss';

type SingleValueWidgetProps = {
    id: string,
    title: string,
    fetchFunction: () => Promise<string>
}

function SingleValueWidget(props: SingleValueWidgetProps) {
    const {id, title, fetchFunction} = props;
    const [data, setData] = useState('');
    const [error, setError] = useState(undefined);
    const [executeSafely] = useStateSafePromise();

    const fetchData = useCallback(() => {
        return executeSafely(fetchFunction).then(
            value => {
                setData(value);
            },
            error => {
                setError(error.message);
            }
        );
    }, [fetchFunction, executeSafely]);

    const [loading] = useLoading(fetchData);

    return (
        <Widget id={id} title={title} loading={loading} error={error}>
            <div className="single-value-widget">
                <div className="value">{data}</div>
            </div>
        </Widget>
    );
};

export { SingleValueWidget };
