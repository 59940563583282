import HighCharts from 'highcharts';
import { Colors } from '@blueprintjs/core';

function DarkTheme() {
    const textBright = Colors.LIGHT_GRAY5; // alias $pt-dark-text-color
    const theme: HighCharts.Options = {
        colors: [   //these are the default colors
            "#7cb5ec",
            "#bcbcb7",  // we only change the second color because it does not work well with dark theme
            "#90ed7d",
            "#f7a35c",
            "#8085e9",
            "#f15c80",
            "#e4d354",
            "#2b908f",
            "#f45b5b",
            "#91e8e1"
        ],
        chart: {
            backgroundColor: Colors.DARK_GRAY4,
        },
        title: {
            style: {
                color: textBright
            }
        },
        subtitle: {
            style: {
                color: textBright
            }
        },
        xAxis: {
            title: {
                style: {
                    color: textBright
                }
            },
            labels: {
                style: {
                    color: textBright
                }
            },
        },
        yAxis: {

            title: {
                style: {
                    color: textBright
                }
            },
            labels: {
                style: {
                    color: textBright
                }
            },
            lineColor: Colors.GRAY1,
            tickColor: Colors.GRAY1,
            gridLineColor: Colors.GRAY1,
        },
        tooltip: {
            style: {
                color: textBright
            },
            backgroundColor: Colors.GRAY1,
            borderColor:Colors.DARK_GRAY2
        },
        plotOptions: {
            area: {
                dataLabels: {
                    color: textBright
                },
                marker: {
                    lineColor: textBright
                }
            },
            series: {
                dataLabels: {
                    color: textBright
                },
                marker: {
                    lineColor: textBright
                }
            },
            boxplot: {
                fillColor: textBright
            },
            candlestick: {
                lineColor: textBright
            },
            errorbar: {
                color: textBright
            },
        },
        legend: {
            title: {
                style: {
                    color: textBright
                }
            },
            itemStyle: {
                color: textBright
            },
            itemHoverStyle: {
                color: Colors.LIGHT_GRAY3
            },
            itemHiddenStyle: {
                color: Colors.GRAY5
            },
        }
    };

    return theme;
}

export { DarkTheme };
