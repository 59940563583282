import React, { useState } from 'react';
import RichTextEditor from 'react-rte';
import { STRINGS } from '../../strings';

const TextEditor = (props) => {
	const format = props.format ? props.format : 'html';

	const initValue = props.initialValue
		? RichTextEditor.createValueFromString(props.initialValue, format)
		: RichTextEditor.createEmptyValue();

	const [editorValue, setEditorValue] = useState(initValue);

	const handleChange = (value) => {
		setEditorValue(value);
		if (props.onChange) {
			props.onChange(value.toString(format));
		}
	};

	const toolbarConfig = {
		// Optionally specify the groups to display (displayed in the order listed).
		display: [
			'INLINE_STYLE_BUTTONS',
			'BLOCK_TYPE_BUTTONS',
			'LINK_BUTTONS',
			'BLOCK_TYPE_DROPDOWN',
		],
		INLINE_STYLE_BUTTONS: [
			{ label: STRINGS.TextEditor.toolbar.bold, style: 'BOLD', className: 'custom-css-class' },
			{ label: STRINGS.TextEditor.toolbar.italic, style: 'ITALIC' },
			{ label: STRINGS.TextEditor.toolbar.underline, style: 'UNDERLINE' },
		],
		BLOCK_TYPE_DROPDOWN: [
			{ label: STRINGS.TextEditor.toolbar.normal, style: 'unstyled' },
			{ label: STRINGS.TextEditor.toolbar.headingLarge, style: 'header-one' },
			{ label: STRINGS.TextEditor.toolbar.headingMedium, style: 'header-two' },
			{ label: STRINGS.TextEditor.toolbar.headingSmall, style: 'header-three' },
		],
		BLOCK_TYPE_BUTTONS: [
			{ label: STRINGS.TextEditor.toolbar.ul, style: 'unordered-list-item' },
			{ label: STRINGS.TextEditor.toolbar.ol, style: 'ordered-list-item' },
		],
		LINK_BUTTONS: [
			{
				label: STRINGS.TextEditor.toolbar.link,
				style: 'unstyled',
				className: 'rte-button rte-button-link',
			},
			{
				label: STRINGS.TextEditor.toolbar.removeLink,
				style: 'unstyled',
				className: 'rte-button rte-button-link-remove',
			},
		],
	};

	return (
		<RichTextEditor
			value={editorValue}
			onChange={handleChange}
			placeholder={props.placeholder}
			toolbarConfig={toolbarConfig}
		/>
	);
};

export { TextEditor };