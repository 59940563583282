import React, { useState, useEffect } from 'react';
import { Classes, Dialog, Button, Tree, Card, Icon, Intent, IconSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ErrorToaster } from '../../components/toast/Toaster';
import { STRINGS } from './../../strings';
import './FacetsChooser.scss';

type Facet = {
  id: string;
  label: string;
  isSelected: boolean;
};

type FacetsChooserProps = {
  facets: Array<Facet>;
  selectedIds: Array<string>;
  handleUpdate: Function;
};

const FacetsChooser = React.forwardRef(
  ({ facets, selectedIds, handleUpdate }: FacetsChooserProps, ref) => {
    React.useImperativeHandle(ref, () => ({
      handleOpen() {
        setIsOpen(!isOpen);
      },
    }));

    const [isOpen, setIsOpen] = useState(false);
    const [availableFacets, setAvailableFacets] = useState<Facet[]>([]);
    const [selectedFacets, setSelectedFacets] = useState<Facet[]>([]);
    const [selectedFacetIds, setSelectedFacetIds] =
      useState<string[]>(selectedIds);

    useEffect(() => {
      setAvailableFacets(
        facets.filter((facet) => !selectedFacetIds.includes(facet.id))
      );

      setSelectedFacets(facets.filter((facet) => selectedFacetIds.includes(facet.id)));
    }, [facets?.length, selectedFacetIds?.length]);

    const selectFacets = () => {
      setSelectedFacetIds([
        ...selectedFacetIds,
        ...availableFacets
          .filter((element) => element.isSelected)
          .map((element) => element.id),
      ]);
    };

    const removeFacets = () => {
      const updatedFacets = [
        ...selectedFacets
          .filter((element) => !element.isSelected)
          .map((element) => element.id)];

      if (updatedFacets.length === 0) {
        ErrorToaster({
          message: STRINGS.FACETS_CHOOSER.error
        });

        return;
      }
      
      setSelectedFacetIds([...updatedFacets]);
    };

    const handleNodeClick = (item) => {
      setAvailableFacets([
        ...availableFacets.map((element) => {
          if (element.id == item.id) {
            element.isSelected = !element.isSelected;
          }
          return element;
        }),
      ]);
    };

    const handleSelectedNodeClick = (item) => {
      setSelectedFacets([
        ...selectedFacets.map((element) => {
          if (element.id == item.id) {
            element.isSelected = !element.isSelected;
          }
          return element;
        }),
      ]);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    const handleSave = () => {
      handleUpdate(selectedFacetIds);
      setIsOpen(false);
    };

    return (
      <>
        <Dialog
          className={Classes.DIALOG}
          title={STRINGS.FACETS_CHOOSER.title}
          isOpen={isOpen}
          autoFocus={true}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          enforceFocus={true}
          usePortal={true}
          onClose={handleClose}
          style={{ width: 0.5 * window.innerWidth, height: '350px' }}
        >
          <Card className="facets-chooser--container">
            <div className="facets-chooser--left-facet">
              <span className='p-4'>
                <b>{STRINGS.FACETS_CHOOSER.availableFacets}</b>
              </span>
              <Tree
                className={`${Classes.ELEVATION_0} facets-chooser--tree-container`}
                contents={availableFacets}
                onNodeClick={handleNodeClick}
              />
            </div>
            <div className="facets-chooser--middle-facet">
              <Arrows
                selectFacets={selectFacets}
                removeFacets={removeFacets}
              />
            </div>
            <div className="facets-chooser--right-facet">
              <span className='p-4'>
                <b>{STRINGS.FACETS_CHOOSER.selectedFacets}</b>
              </span>
              <Tree
                className={`${Classes.ELEVATION_0} facets-chooser--tree-container`}
                contents={selectedFacets}
                onNodeClick={handleSelectedNodeClick}
              />
            </div>
          </Card>
          <DialogFooter handleClose={handleClose} handleSave={handleSave} />
        </Dialog>
      </>
    );
  }
);

const DialogFooter = (props: {
  handleClose: (e: React.MouseEvent) => void;
  handleSave: (e: React.MouseEvent) => void;
}) => {
  return (
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button onClick={props.handleClose}>{STRINGS.FACETS_CHOOSER.cancel}</Button>
        <Button onClick={props.handleSave} intent={Intent.SUCCESS}>{STRINGS.FACETS_CHOOSER.apply}</Button>
      </div>
    </div>
  );
};

const Arrows = ({ selectFacets, removeFacets }) => {
  return (
    <div className='d-flex flex-column' style={{ gap: '8px' }}>
      <a onClick={selectFacets}>
        <Icon icon={IconNames.ARROW_RIGHT} size={IconSize.LARGE} intent={Intent.NONE} />
      </a>
      <br />
      <a onClick={removeFacets}>
        <Icon icon={IconNames.ARROW_LEFT} size={IconSize.LARGE} intent={Intent.NONE} />
      </a>
    </div>
  );
};

export { FacetsChooser };
