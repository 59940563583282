const en = {
  apply: 'Apply',
  cancel: 'Cancel',
  confirm: 'Confirm',
  copy: 'Copy',
  close: 'Close',
  COLUMN_CHOOSER: {
    title: 'Column Chooser',
    availableColumns: 'Available Columns',
    selectedColumns: 'Selected Columns',
    errorMessage:
      'Unable to remove all facets. Please select at least one facet.',
    remove: 'Remove',
    add: 'Add',
    apply: 'Apply',
    cancel: 'Cancel',
  },
  FACETS_CHOOSER: {
    title: 'Facets Chooser',
    availableFacets: 'Available Facets',
    selectedFacets: 'Selected Facets',
    apply: 'Apply',
    cancel: 'Cancel',
    error: 'Removing all the facets is not allowed.',
  },
  DATE_TIME_PICKER_MODAL: {
    title: 'Date range',
    errors: {
      daterange_invalid: 'Invalid date range selected',
    },
  },
  DATE_RANGE_TAG: {
    startDate: 'Start date',
    endDate: 'End date',
  },
  iconPathWarning:
    'Warning: The icon svg path information is mentioned directly as a child from the consuming app to the component. ' +
    'All the icon svg path information should be added to the IconLibrary.',
  selectDateRange: 'Select the date range',
  STRUCTURED_FILTER: {
    addFilter: 'Add filter',
    filterOperator: 'Filter operator',
    clearFilter: 'Clear Filter',
    resetFilter: 'Reset Filter',
    apply: 'Apply',
    filterValue: 'Filter value',
    operators: {
      contains: 'contains',
      equals: 'equals',
    },
  },
  submit: 'Submit',
  TABLE: {
    noData: 'No data to display.',
    columnChooser: 'Column Chooser',
    downloadCsv: 'Download as CSV',
  },
  TextEditor: {
    toolbar: {
      bold: 'Bold',
      italic: 'Italic',
      underline: 'Underline',
      normal: 'Normal',
      headingLarge: 'Heading Large',
      headingMedium: 'Heading Medium',
      headingSmall: 'Heading Small',
      ul: 'UL',
      ol: 'OL',
      link: 'Link!',
      removeLink: 'Remove link',
    },
  },
};

export { en };
