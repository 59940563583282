import React, { useCallback, useState } from 'react';
import * as Highcharts from 'highcharts';
import { Chart } from './../charts';
import { Widget } from './Widget';
import { useLoading, useStateSafePromise } from './../../hooks';

type ChartWidget = {
    id: string,
    title?: string,
    fetchFunction: ()=>Promise<Highcharts.Options>,
    baseChartOptions : Highcharts.Options
}

function ChartWidget(props: ChartWidget) {

    const {id, title, fetchFunction, baseChartOptions} = props;
    const [chartOptions, setChartOptions] = useState(baseChartOptions);
    const [error, setError] = useState(undefined);
    const [executeSafely] = useStateSafePromise();

    const fetchData = useCallback(() => {
        return executeSafely(fetchFunction).then(
            chartOptions => {
                setChartOptions(chartOptions);
            },
            error => {
                setError(error.message);
            }
        );
    }, [fetchFunction, executeSafely]);

    const [loading] = useLoading(fetchData);

    return (
        <Widget id={id} title={title} loading={loading} error={error}>
            <Chart options={chartOptions}/>
        </Widget>
    );
}

export { ChartWidget };
