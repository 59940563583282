import React from 'react';
import { Button, H2, Spinner } from '@blueprintjs/core';
import { ActionsMenu, menuActionType } from '../menu/ActionsMenu';
import { LoadingOverlay } from '../loading/LoadingOverlay';
import { Icon, IconName, IconNames } from "../../icons";
import { PageTopLevelHelp } from "../../components/help";
import './Page.scss';

type PageProps = {
    title: string;
    children: React.ReactNode;
    icon?: IconName;
    loading?: boolean;
    actions?: menuActionType[];
    extraActions?: menuActionType[];
    onRefresh?: ()=>void;
    // not needed, just there to be able to just pass a route to the component and declare everything only once
    path?: string;
    component?: any;
    helpInfo?: {[index:string]: string};
};
function Page(props: PageProps) {

    const refresh = () => {
        if (props.onRefresh) {
            props.onRefresh();
        }
    };

    return (
        <div className="page">
            <div className="page-scrollable-area">
                <LoadingOverlay visible={props.loading} spinnerSize={Spinner.SIZE_LARGE}/>
                <div className="page-titlebar">
                    <div className="page-title">
                        <H2><Icon icon={props.icon} iconSize={30}/> {props.title}</H2>
                    </div>
                    { props.onRefresh ? <Button icon={IconNames.REFRESH} minimal={true} onClick={refresh}/> : null }
                    <div className='align-self-center'>
                        {
                            props.helpInfo &&
                            <PageTopLevelHelp
                                comment={props.helpInfo.comment}
                                helpSummary={props.helpInfo.helpSummary}
                                showMore={Boolean(props.helpInfo.showMore)}
                                url={props.helpInfo.url}
                            />
                        }
                    </div>
                </div>
                <ActionsMenu actions={props.actions} extraActions={props.extraActions}/>
                <div className="page-content">
                    {props.children}
                </div>
            </div>
        </div>
    );

}

export { Page };
