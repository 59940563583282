import React, { useCallback, useMemo, useState } from 'react';
import { ServerTable } from './ServerTable';
import { TableFilter, makeInnerColumnsAndFilterDef } from './TableFilter';
import { FilterableTableProps } from './StaticFilterableTable';

type FilterableServerTableProps = Omit<FilterableTableProps, 'data'>;

export const FilterableServerTable = (props: FilterableServerTableProps) => {
    const {
        columns,
        startingDateRange,
        filterConfig = [],
        startingFilters,
        clearToStarting,
        fetchFn,
        ...tableProps
    } = props;
    const [filters, setFilters] = useState([]);

    const onFiltersApplied = filters => {
        setFilters(filters);
    };

    const processFilters = filters => {
        let pFilters = [];
        for (const key of Object.keys(filters)) {
            pFilters[key] = filters[key].value;
        }
        return pFilters;
    };

    const _fetchFn = useCallback(
        paginationParams => {
            const params = { ...paginationParams, ...processFilters(filters) };
            return fetchFn(params);
        },
        [fetchFn, filters]
    );

    //extract filter config from columns def
    const [_columns, _filterConfig] = useMemo(
        () => makeInnerColumnsAndFilterDef(columns, filterConfig),
        [columns, filterConfig]
    );

    return (
        <div>
            <TableFilter
                filterConfig={_filterConfig}
                startingFilters={startingFilters}
                clearToStarting={clearToStarting}
                startingDateRange={startingDateRange}
                onApply={onFiltersApplied}
            />
            <ServerTable
                columns={_columns}
                fetchFn={_fetchFn}
                fetchOnLoad={false}
                {...tableProps}
            />
        </div>
    );
};
