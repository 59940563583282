import React, { useState, useEffect, useRef } from 'react';
//import * as Highcharts from 'highcharts';
//import HighCharts from 'highcharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DarkTheme } from './themes/DarkTheme';
import { LightTheme } from './themes/LightTheme';
import './Chart.scss';
import { TIR_UI_THEME_CHANGE, ThemeManager } from '../../themes/ThemeManager';

export const TIR_CHART_REFLOW_EVENT = 'tir-ui-chart-reflow';

//These are the base options we want for all charts, charts can override them if they want a different behavior
const baseChartOptions: Highcharts.Options = {
    title: {
        text: undefined // charts by default should not have a title or it will default to an ugly "Chart title" if not passed
    },
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: false,
    },
    chart: {
        styledMode: true, //not sure if working in 6.2.0
        style: {    //this does not work via css for some reason (6.2.0 issue?)
            fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",' +
                ' "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        },
        animation: false
    },
    tooltip: {
        className: 'highcharts-tooltip',
    },
    xAxis: {
        dateTimeLabelFormats: { //adjust other cases based on feedback
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%b %e',
            week: '%b %e',
            month: '%b \'%y',
            year: '%Y'
        }
    },
    plotOptions: {
        series: {
            events: {
                legendItemClick: function () {
                    return false;
                },
            },
            marker: {
                enabledThreshold: 25
            },
            animation: false
        },
    },
};

function Chart(props: HighchartsReact.Props) {

    const chart = useRef<HighchartsReact.RefObject>();
    const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
    const [initialized, setInitialized] = useState(false);
    const {options, ...chartProps} = props;

    useEffect(() => {

        //theme handling
        const onThemeChange = () => {
            chart.current?.chart.update(ThemeManager.getTheme() === 'dark' ? DarkTheme() : LightTheme());
        };

        //apply the theme as base chart options could be done globally but then we need to manage the theme change event globally too
        Highcharts.setOptions(ThemeManager.getTheme() === 'dark' ? DarkTheme() : LightTheme());

        window.addEventListener(TIR_UI_THEME_CHANGE, onThemeChange);

        //sidebar caused resize handling
        const onChartReflow = () => {
            chart.current?.chart.reflow();
        };

        window.addEventListener(TIR_CHART_REFLOW_EVENT, onChartReflow);


        return function cleanup() {
            window.removeEventListener(TIR_UI_THEME_CHANGE, onThemeChange);
            window.removeEventListener(TIR_CHART_REFLOW_EVENT, onChartReflow);
        };

    }, []);

    //options update handling
    useEffect(() => {
        if (initialized) {
            //just update
            setChartOptions(options);
        } else {
            setChartOptions(Highcharts.merge(baseChartOptions, options));
            setInitialized(true);
        }

    }, [options, initialized]);

    if (initialized) {  //skip first render, we need the logic in useEffect to happen first
        return (
            <div className='chart-grid-container'>
                <HighchartsReact
                    highcharts={props.highcharts || Highcharts}
                    options={chartOptions}
                    {...chartProps}
                    containerProps = {{ className: 'chart-container' }}
                    ref={ chart as React.RefObject<HighchartsReact.RefObject> }
                />
            </div>
        );
    }
    return null;

}

export { Chart };
