import LocalizedStrings from 'react-localization';
import { en } from './en';
//import it from './it'; // add more language is we ever translate things


const STRINGS = new LocalizedStrings({
    en: en
    //it: it
});

export { STRINGS };
