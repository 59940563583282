import HighCharts from 'highcharts';
import { Colors } from '@blueprintjs/core';

function LightTheme() {
    const textBlack = Colors.DARK_GRAY1; //alias: $pt-text-color
    const theme: HighCharts.Options = {
        colors: [   //these are the default colors
            "#7cb5ec",
            "#434348",
            "#90ed7d",
            "#f7a35c",
            "#8085e9",
            "#f15c80",
            "#e4d354",
            "#2b908f",
            "#f45b5b",
            "#91e8e1"
        ],
        chart: {
            backgroundColor: Colors.WHITE,
        },
        title: {
            style: {
                color: textBlack
            }
        },
        subtitle: {
            style: {
                color: textBlack
            }
        },
        xAxis: {
            title: {
                style: {
                    color: textBlack
                }
            },
            labels: {
                style: {
                    color: textBlack,
                }
            },
        },
        yAxis: {
            title: {
                style: {
                    color: textBlack
                }
            },
            labels: {
                style: {
                    color: textBlack
                }
            },
            lineColor: Colors.LIGHT_GRAY1,
            tickColor: Colors.LIGHT_GRAY1,
            gridLineColor: Colors.LIGHT_GRAY1,

        },
        tooltip: {
            style: {
                color: textBlack
            },
            backgroundColor: Colors.WHITE,
            borderColor:Colors.LIGHT_GRAY5
        },
        plotOptions: {
            series: {
                dataLabels: {
                    color: textBlack
                },
                marker: {
                    lineColor: textBlack
                }
            },
            boxplot: {
                fillColor: textBlack
            },
            candlestick: {
                lineColor: textBlack
            },
            errorbar: {
                color: textBlack
            },
        },
        legend: {
            title: {
                style: {
                    color: textBlack
                }
            },
            itemStyle: {
                color: textBlack,
            },
            itemHoverStyle: {
                color: Colors.GRAY1
            },
            itemHiddenStyle: {
                color: Colors.LIGHT_GRAY1
            },
        }
    };

    return theme;
}

export { LightTheme };
