import React from 'react';

import './GridLayout.scss';

type Element = {
    el: React.FunctionComponent | React.ComponentClass;
    width: number;
};

type GridLayoutProps = {
    id: string;
    columns: number;
    elements: Array<Element>;
};

const GridLayout = (props: GridLayoutProps) => {
    return (
        <div key={props.id} className={`grid-layout ${'columns-' + props.columns}`}>
            {props.elements.map(item => {
                return (
                    <div key={item.el.name} className={`${'span-' + item.width}`}>
                        {React.createElement(item.el)}
                    </div>
                );
            })}
        </div>
    );
};

export { GridLayout };
