import { useState, useEffect } from 'react';
import { ErrorToaster } from './../components/toast';

//polling is evil, use sparingly, websockets would be nice
export function useDataPolling<T>(f: (() => Promise<T>), defaultValue: T, interval: number = 10000): [T] {

    const [data, setData] = useState(defaultValue);

    useEffect(() => {
        let valid = true;

        //call the function the first time manually
        pollingFunction();

        //repeat every <interval> time
        const timer = setInterval(pollingFunction, interval);

        function pollingFunction() {
            f().then(
                (response) => {
                    if (valid) {
                        setData(response);
                    }
                },
                (error) => {
                    //handle error here
                    if (valid) {
                        //data stays undefined
                        ErrorToaster({
                            message: error.message
                        });
                    }
                }
            );
        }

        return () => {
            //cleanup, this will avoid setState on unmounted component
            clearInterval(timer);
            //should already not happen because the interval is cleared, but using the same mechanism as useDataLoading
            valid = false;
        };
    }, [f, interval]);

    return [data];
}
