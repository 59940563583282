import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useLoading, useStateSafePromise } from './../../hooks';
import { Table, TableProps } from './Table';
import {
    makeInnerColumnsAndFilterDef,
    TableFilter,
    TableFilterProps,
} from './TableFilter';
import { filterConfigToken } from './../structured-filter/StructuredFilterTypes';
import { filterData } from './../structured-filter/StructuredFilterOperators';

export type FilterableTableProps = Omit<TableFilterProps,
    'applyFilterFn' | 'filterConfig'> & {
    filterConfig?: filterConfigToken[];
    fetchFn: Function;
} & TableProps;

const _staticFilterFn = (filters, data) => {
    return new Promise(resolve => {
        let filteredData: any[] = [];
        if (Object.keys(filters).length) {
            filteredData = _.filter(data, function (item) {
                return filterData(item, filters);
            });
        } else {
            filteredData = data;
        }
        resolve(filteredData);
    });
};

export const StaticFilterableTable = (props: FilterableTableProps) => {
    const {
        columns,
        startingDateRange,
        filterConfig = [],
        startingFilters,
        clearToStarting,
        fetchFn,
        ...tableProps
    } = props;

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [error, setError] = useState(undefined);
    const [executeSafely] = useStateSafePromise();
    const [filters, setFilters] = useState([]);

    const _fetchFn = useCallback(() => {
        return executeSafely(fetchFn()).then(
            response => {
                // @ts-ignore
                //this assumes all apis will return "items" which is wrong, this component will require improvement
                setData(response.items);
            },
            error => {
                setData([]);
                setError(error.message);
            }
        );
    }, [fetchFn, setData, setError, executeSafely]);

    let [loading] = useLoading(_fetchFn);

    useEffect(() => {
        _staticFilterFn(filters, data).then(response => {
            // @ts-ignore
            setFilteredData(response);
        });
    }, [setFilteredData, data, filters]);

    //extract filter config from columns def
    const [_columns, _filterConfig] = useMemo(
        () => makeInnerColumnsAndFilterDef(columns, filterConfig),
        [columns, filterConfig]
    );

    return (
        <div>
            <TableFilter
                filterConfig={_filterConfig}
                startingFilters={startingFilters}
                startingDateRange={startingDateRange}
                clearToStarting={clearToStarting}
                onApply={filters => setFilters(filters)}
            />

            <Table
                columns={_columns}
                data={filteredData}
                loading={loading}
                noDataText={error}
                {...tableProps}
            />
        </div>
    );
};
