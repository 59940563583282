import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import { Colors } from '@blueprintjs/core';
import { Chart } from './Chart';
import { DateRangeChart } from './DateRangeChart';

highchartsMore(Highcharts);
solidGauge(Highcharts);

//These are the base options we want for all charts, charts can override them if they want a different behavior
const baseChartOptions: Highcharts.Options = {
    chart: {
        type: 'solidgauge',
    },
    yAxis: {
        stops: [[0.1, Colors.GREEN4]],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        ceiling: 10,
        tickAmount: 2,
        labels: {
            y: 16,
        },
        min: 0,
        max: 10,
    },
    pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: [
            {
            innerRadius: '60%',
                outerRadius: '100%',
            shape: 'arc',
            },
        ],
    },
    tooltip: {
        enabled: false,
    },
    plotOptions: {
        solidgauge: {
            dataLabels: {
                y: 5,
                borderWidth: 0,
                useHTML: true,
            },
        },
    },
};

/**
 * Gauge Chart will automatically use chart or dateChart based on what props are passed
 */

function GaugeChart(props: HighchartsReact.Props & { onDateRangeChange?:(dr)=>void, defaultDateRange?}) {

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
    const [initialized, setInitialized] = useState(false);
    const {options, ...chartProps} = props;

    useEffect(() => {
        if (initialized) {
            //just update
            setChartOptions(options);
        } else {
            //set the base options
            setChartOptions(Highcharts.merge(baseChartOptions, options));
            setInitialized(true);
        }
    }, [options, initialized]);

    // Gauge Chart will automatically use chart or dateChart based on what props are passed
    if(props.onDateRangeChange) {
        const {onDateRangeChange = ()=>{}, defaultDateRange, ...dateChartProps} = chartProps;

        return (
            <DateRangeChart
                highcharts={Highcharts}
                options={chartOptions}
                defaultDateRange={ defaultDateRange}
                onDateRangeChange={onDateRangeChange}
                {...dateChartProps}
            />
        )
    }

    return (
        <Chart
            highcharts={Highcharts}
            options={chartOptions}
            {...chartProps}
        />
    );
}

export { GaugeChart };
