import { Intent, IToastProps, Position, Toaster } from '@blueprintjs/core';

/** Singleton toaster instance. Create separate instances for different options. */
const AppToaster = Toaster.create({
    position: Position.TOP,
    maxToasts: 1
});

const SuccessToaster = (props: IToastProps) => {
    const { intent, ...passThroughProps} = props;
    AppToaster.show({intent: Intent.SUCCESS, ...passThroughProps});
};

const ErrorToaster = (props: IToastProps) => {
    const { intent, ...passThroughProps} = props;
    AppToaster.show({intent: Intent.DANGER, ...passThroughProps});
};

/** Use the `ShowToaster` function to display toaster on the top of the page */
const ShowToaster = (props: IToastProps) => {
    AppToaster.show(props);
}

export { ErrorToaster, SuccessToaster, ShowToaster};

