import moment from 'moment';
import * as Highcharts from 'highcharts';

export const createTimeSeriesTooltipFormatter = function(valueformat = (x) => x) {
    return function() {
        // @ts-ignore this is an highcharts quirk
        const self: Highcharts.TooltipFormatterContextObject = this;
        return self.points?.reduce(function (s, point) {
            return s + '<br/>' + point.series.name + ': ' + valueformat(point.y);
        }, '<b>' + moment.utc(self.x).format('dddd, MMMM D, YYYY, H:mm') + '</b>');
    }
};

export const createDataChartTickPositioner = function() {
    /**
     * Hicharts has an elaborate algorithm to determine which gridlines
     * (or ticks) to display for a given range of data. It mimicks the way
     * people would naturally pick those lines: for large numbers, the
     * value will be some round number of a power of 1000, so they can be
     * displayed as small numbers with a metric prefixes.
     * We however wish to display quantities of bytes as small numbers
     * with binary prefixes. We can do this by converting these values
     * from their binary prefix counterparts, i.e. 20 MB to 20 MiB.
     * The magnitude is similar, so it should have negligeable impact on
     * the values selected by highchart's algorithm (the values will be
     * about 10% larger at the tera scale).
     */
    return function() {
        // Highcharts will have already selected the gridline values and
        // stored them in this.tickPositions. We will simply convert them.

        // @ts-ignore this is an highcharts quirk
        return this['tickPositions'].map((position: number) => {
            // powerOf1000 is essentially a numerical representation of the
            // position's metric prefix.
            // Eg: 20,345,987 is 20M, so powerOf1000 is 2
            const powerOf1000 = Math.floor(Math.log10(position) / 3);
            // The position is scaled by the ratio between 2^10 and 10^3 for
            // every prefix step, which corresponds to the powerOf1000
            return position * Math.pow(1024 / 1000, powerOf1000);
        });
    }
};
