import React, { useState } from 'react';
import { Alert, Button, Callout, Classes, Dialog, Intent } from '@blueprintjs/core';
import { STRINGS } from '../../strings';
import { LoadingOverlay } from '../loading/LoadingOverlay';
//import { openModal } from './ModalRenderer';
import './Modal.scss';

type ModalButton = {
    label: string;
    action: () => void;
    intent?: Intent;
    disabled?: boolean;
}

type ModalProps = {
    children: React.ReactNode;
    title?: React.ReactNode;
    onSubmit?: Function;
    onSuccess?: Function;
    onClose?: Function;
    hideCancel?: boolean;
    hideSubmit?: boolean;
    customLabels?: {
        close?: string,
        submit?: string,
    },
    loading?: boolean;
    buttons?: ModalButton[];
    alertOnError?: boolean; //todo implement
    closeOnError?: boolean; //todo implement
    usePortal?: boolean
}

//icon?

/**
 * This is the standard modal implementation, should cover most common use cases (usually forms)
 */
function Modal (props: ModalProps) {

    /*
    if we start with true, and then the modal handles itsown close, we should be good
     */
    const [modalOpen, setModalOpen] = useState(true);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalError, setModalError] = useState<string|undefined>(undefined);
    const {
      customLabels,
      buttons = [],
      hideCancel = false,
      hideSubmit = false,
    } = props;

    function handleClose (success?: boolean, data?: object) {
        setModalOpen(false);

        //todo this might need some fine tuning to cover some cases
        if (success && props.onSuccess) {
            props.onSuccess(data);
        }
        if (props.onClose) {
            props.onClose(data);
        }
    }

    async function handleSubmit () {
        setModalLoading(true);
        try {
            let res;
            if (props.onSubmit) {
                res = await props.onSubmit();
            }
            setModalLoading(false);
            handleClose(true, res);
        } catch (e) {
            setModalLoading(false);
            // this crashes if the api returns something that is not json like an html page
            //in theory it can be ignored if we control the api otherwise needs some kind of protection
            //todo move error management to api service, here should arrive only a string
            if (e instanceof Error) {
                setModalError(e.message);
            }
        }
    }

    function renderFooter () {
        return ( 
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    {buttons && buttons.map((button, index) =>
                        <Button 
                            key={index}
                            onClick={() => button.action()}
                            intent={button.intent}
                            disabled={button?.disabled}>
                                {button.label}
                        </Button>
                    )}
                    {!hideCancel && <Button onClick={() => handleClose()}>{customLabels?.close ? customLabels.close : STRINGS.close}</Button>}
                    {!hideSubmit && <Button intent={Intent.PRIMARY} onClick={handleSubmit}>{customLabels?.submit ? customLabels?.submit : STRINGS.submit}</Button>}
                </div>
            </div>
        );
    }

    //todo handle props title etc etc
    return (
        <Dialog onClose={() => handleClose()}
                title={props.title}
                isOpen={modalOpen}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                className="modal-container"
                usePortal={!!props.usePortal}
        >
            <LoadingOverlay visible={modalLoading || props.loading}/>
            <div className={Classes.DIALOG_BODY}>
                {props.children}
                {modalError && !props.alertOnError ? <Callout intent={Intent.DANGER}>{modalError}</Callout> : null}
                <Alert isOpen={!!modalError && props.alertOnError}>{modalError}</Alert>
            </div>
            {renderFooter()}
        </Dialog>
    );

}

export { Modal };
