import React from 'react';
import { Popover, Button, Menu, Position } from '@blueprintjs/core';

/** Dropdown Menu component is a convenience component for simple or general use dropdowns.
Renders a Button toggle and all children are passed directly to the default Menu. */
function DropdownMenu({ children, ...otherProps}: { children: React.ReactNode, [x: string]: any; }) { // this should be children + the Button props

    return (
        <Popover minimal={true} position={Position.BOTTOM} content={<Menu>{children}</Menu>}>
            <Button {...otherProps} />
        </Popover>
    );

}

export { DropdownMenu };
